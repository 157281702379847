<template>
  <div class="dataAnalysisDetails">
    <!-- 主体 -->
    <div class="main">
      <!-- <div class="return">
        <div
          class="back"
          @click="
            oldData = {};
            $emit('backClick');
          "
        >
          <img
            :src="require('assets/images/goodsGetails/detail_slide_left.png')"
            alt=""
          />
          返回
        </div>

        <div class="operation">
          <div class="item" @click="download">下载文章</div>
        </div>
      </div> -->

      <!-- 模板相关 -->
      <!-- <div
        class="title_box"
        v-if="
          $route.path != '/infoCompileIndex' &&
          $route.path != '/dataAnalysisIndex' &&
          $route.path != '/traceSource' &&
          $route.path != '/recommendExperience'
        "
      >
        <img class="imgIcom" :src="require('assets/images/wps.png')" alt="" />
        <div class="content">
          <div class="text">{{ openLibraryFile.name }}</div>
          <div class="obj">
            <div class="date">上传: {{ openLibraryFile.createTime }}</div>
            <div class="hot">阅读: 999+</div>
          </div>
        </div>
      </div> -->
      <div class="header-option">
        <div class="option">
          <div class="back" v-if="!isBack" @click="$emit('backClick')">
            <i class="el-icon-arrow-left"></i>返回
          </div>
          <div class="download" @click="download">下载Word版</div>
          <div class="download" @click="findPdfVersion">预览Word版</div>
        </div>
        <!-- <div class="obj">
            <div class="date">上传: {{ htmlText.createTime }}</div>
            <div class="hot">阅读: {{ htmlText.wordNum }}</div>
          </div> -->
      </div>
      <!-- 内容 -->
      <div class="file_body">
        <div
          v-if="activeName == '2'"
          class="TraceSourceVersion"
          @click="findTraceSourceVersion"
        >
          溯源版原创率:{{ traceSourceEvaluation }}% 溯源时间:{{
            traceSourceDate
          }}
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="标准版" name="1"
            ><div
              class="file_body_html"
              style="line-height: 1.8; text-align: justify"
              v-html="openLibraryFile.html"
            ></div>

            <div
              v-if="!openLibraryFile.payStatus"
              class="blue"
              @click="getChooseBuy"
            >
              <img :src="require('assets/images/login-suo.png')" alt="" />
              预览结束，购买{{ openLibraryFile.viptip }}之后可下载
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="isTraceSource" label="溯源版" name="2"
            ><div
              class="file_body_html"
              style="line-height: 1.8; text-align: justify"
              v-html="openLibraryFile.html"
            ></div>

            <div
              v-if="!openLibraryFile.payStatus"
              class="blue"
              @click="getChooseBuy"
            >
              <img :src="require('assets/images/login-suo.png')" alt="" />
              预览结束，购买{{ openLibraryFile.viptip }}之后可下载
            </div>
          </el-tab-pane>

          <el-tab-pane label="关键词分析" name="3">
            <div class="wordList_title">关键词数量</div>
            <div class="wordList_box">
              <div class="wordList" v-for="item in wordList" :key="item">
                <el-tag>{{ item }}</el-tag>
              </div>
            </div>

            <div id="myChart" :style="{ height: '500px' }"></div>
          </el-tab-pane>
        </el-tabs>
        <!-- 底部 -->
        <div class="bottom_box" v-if="!openLibraryFile.payStatus">
          <div
            class="btn bgc"
            v-if="!openLibraryFile.payStatus"
            @click="getChooseBuy"
          >
            {{
              openLibraryFile.author == 0
                ? `开通会员(${openLibraryFile.viptip})`
                : `在线购买/授权`
            }}
          </div>
        </div>
      </div>
    </div>

    <!--内容溯源授权许可-->
    <AuthorizationInfo
      @PaySuccess="PaySuccess"
      ref="authorize"
      v-if="authorize"
    />

    <idiomTips
      @closeClick="isShow = false"
      @paySuccess="paySuccess"
      :isShow="isShow"
      ref="idiomTips"
      :dataType="5"
    />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import * as echarts from "echarts";
import {
  checkPortfolioAuthorization,
  openLibraryTraceSourceFile,
  openLibraryFile,
  keyword,
} from "api/infoCompile";
import idiomTips from "components/idiomTips";
import AuthorizationInfo from "components/traceSource/TraceSourceAuthorizationInfo";
import { wordToHtml, logAdd, recordAdd } from "api/search";
import { getToken } from "utils/auth";
import { previewUrl } from "api/common";
import { settings } from "api/service";
import qs from "qs";
export default {
  name: "dataAnalysisDetails",
  //import引入的组件需要注入到对象中才能使用
  components: {
    AuthorizationInfo,
    idiomTips,
  },
  props: {
    isBack: {
      default: false,
    },
  },
  data() {
    //这里存放数据
    return {
      htmlText: "",
      buyDialog: false,
      authorize: false,
      textMd5: "",
      activeName: "1",
      openLibraryFile: "",
      md5Label: "",
      originalId: "",
      oldData: {},
      traceSourceDate: "",
      traceSourceEvaluation: "",
      isTraceSource: false,
      // echarts
      wordList: [],
      keyList: [],
      valueList: [],
      isShow: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    openLibraryFile(newVal, oldVal) {
      this.$nextTick(() => {
        const title1 = document.querySelectorAll(
          ".file_body_html > div > p:first-child"
        );
        const title2 = document.querySelectorAll(
          ".file_body_html > div > p:nth-child(2)"
        );
        console.log(title1, title2);
        title1.forEach((item) => {
          item.style.textAlign = "center";
          item.style.fontWeight = "bold";
        });
        title2.forEach((item) => {
          item.style.textAlign = "center";
        });
      });
      this.oldData = oldVal;
      if (newVal.md5Label) {
        this.md5Label = newVal.md5Label;
        this.originalId = newVal.originalId;
        this.traceSourceDate = newVal.traceSourceDate;
        this.traceSourceEvaluation = newVal.traceSourceEvaluation;
        this.activeName = newVal.title ? "1" : "2";
        this.isTraceSource = newVal.isTraceSource;
      }

      // console.log(newVal);
    },
  },
  //方法集合
  methods: {
    // 成语券支付成功
    paySuccess() {
      if (this.activeName == "1") {
        window.open(this.openLibraryFile.url);
        this.isShow = false;
      } else {
        window.open(
          this.openLibraryFile.url +
            "?attname=" +
            this.openLibraryFile.fileName +
            ".docx"
        );
        this.isShow = false;
      }
      let data = {
        textMd5: this.openLibraryFile.textMd5,
      };
      this.Addlog(data);
      // this.$message.success("快捷支付成语券成功");
    },
    // 下载日志
    async Addlog(arr) {
      const res = await recordAdd(arr);
      if (res.code == 200) {
        return true;
      } else if (res.code == 2012) {
        // this.$message.error(res.message);
        this.$refs["idiomTips"].getUserinfo();
        let res1 = await settings();
        if (res1.data.aiImg == 0) {
          this.isShow = true;
        } else {
          await this.$refs["idiomTips"].ticketPay();
          // this.$message.success("快捷支付成语券成功");
        }
        return false;
      } else {
        this.$message.error(res.message);
        return false;
      }
    },

    async getKeyword() {
      const data = {
        md5: this.openLibraryFile.textMd5,
      };

      const res = await keyword(qs.stringify(data));
      console.log(res);

      if (res.code == 200) {
        this.wordList = res.data.data;
        this.keyList = res.data.key;
        this.valueList = res.data.value;

        this.$nextTick(() => {
          this.mybarfun(this.keyList, this.valueList);
        });
      }
    },

    // 柱形图
    mybarfun(keyList, valueList) {
      let myChart = echarts.init(document.getElementById("myChart"), null, {
        renderer: "svg",
      });
      // 绘制图表
      myChart.setOption({
        title: {
          text: "关键词前10排名",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          title: "出现次数",
          type: "value",
        },
        yAxis: {
          title: "关键词",
          type: "category",
          data: keyList,
        },
        series: [
          {
            data: valueList,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            //显示数值
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在右方显示
                  textStyle: {
                    //数值样式
                    color: "#FFFFFF",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      });
    },

    PaySuccess() {
      this.getCheckPortfolioAuthorization();
    },
    async getCheckPortfolioAuthorization() {
      if (this.activeName == "1") {
        const data = {
          md5Label: this.md5Label,
          originalId: this.originalId,
          // templateId: "",
        };
        const resdata = await openLibraryFile(qs.stringify(data));
        console.log(resdata);
        if (resdata.data.status) {
          this.openLibraryFile = {
            ...resdata.data.obj,
            html: resdata.data.html,
            payStatus: resdata.data.status,
            url: resdata.data.url,
            viptip: resdata.data.viptip,
            author: resdata.data.author,
          };
        }
      } else {
        const data = {
          md5Label: this.md5Label,
          originalId: this.originalId,
          // templateId: "",
        };
        const resdata = await openLibraryTraceSourceFile(qs.stringify(data));
        if (resdata.data.status) {
          this.openLibraryFile = {
            ...resdata.data.obj,
            html: resdata.data.html,
            payStatus: resdata.data.status,
            url: resdata.data.url,
            viptip: resdata.data.viptip,
            author: resdata.data.author,
          };
        }
      }
    },
    handleClick(tab, event) {
      if (this.activeName == "1") {
        this.findVersion();
      } else if (this.activeName == "2") {
        this.findTraceSourceVersion();
      } else {
        this.getKeyword();
      }
    },
    async findVersion() {
      const data = {
        md5Label: this.md5Label,
        originalId: this.originalId,
        // templateId: "",
      };
      const resdata = await openLibraryFile(qs.stringify(data));

      this.openLibraryFile = {
        ...resdata.data.obj,
        html: resdata.data.html,
        payStatus: resdata.data.status,
        url: resdata.data.url,
        viptip: resdata.data.viptip,
        author: resdata.data.author,
      };
    },
    async findTraceSourceVersion() {
      const data = {
        md5Label: this.md5Label,
        originalId: this.originalId,
        // templateId: "",
      };
      const resdata = await openLibraryTraceSourceFile(qs.stringify(data));

      this.openLibraryFile = {
        ...resdata.data.obj,
        html: resdata.data.html,
        payStatus: resdata.data.status,
        url: resdata.data.url,
        viptip: resdata.data.viptip,
        author: resdata.data.author,
      };
    },
    getChooseBuy() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.openLibraryFile.author == 0) {
        // try {
        let TokenObj = getToken();
        let access_token = "";
        if (TokenObj) {
          TokenObj = JSON.parse(TokenObj);
          access_token = `${TokenObj.access_token}`;
        }
        let vip = 1;
        typeof this.openLibraryFile.currentClickItemVip !== "undefined";
        if (typeof this.openLibraryFile.currentClickItemVip !== "undefined") {
          vip = this.openLibraryFile?.currentClickItemVip?.isVip - 1;
        }
        if (this.$store.state.shopNav) {
          // window.open();
          this.$router.push(`/details/1/${vip}`);
        } else {
          window.open(`https://www.writemall.com/details/1/${vip}`);
        }

        // } catch (e) {
        //   console.log(e);
        // }
        return;
      }
      console.log(this.openLibraryFile);
      let data = {
        textMd5: this.openLibraryFile.textMd5,
      };
      this.authorize = true;
      this.$nextTick(() => {
        this.$refs["authorize"].authorization(data);
      });
    },
    findPdfVersion() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.openLibraryFile.payStatus == false) {
        this.getChooseBuy();
        return;
      }
      previewUrl({
        url: this.openLibraryFile.url,
      }).then((res) => {
        window.open(res.data);
      });
    },
    // 下载
    async download() {
      let arr = {
        textMd5: this.openLibraryFile.textMd5,
      };

      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.openLibraryFile.payStatus == false) {
        this.getChooseBuy();

        return;
      }
      if (this.activeName == "1") {
        let res = await this.Addlog(arr);
        if (res) {
          window.open(this.openLibraryFile.url);
        }
      } else {
        let res = await this.Addlog(arr);
        if (res) {
          window.open(
            this.openLibraryFile.url +
              "?attname=" +
              this.openLibraryFile.fileName +
              ".docx"
          );
        }
      }
    },

    // 打开
    open() {
      if (this.openLibraryFile.payStatus == false) {
        this.getChooseBuy();
        return;
      }
      // try {
      //   // eslint-disable-next-line
      //   OpenDocument2(
      //     this.htmlText.filename,
      //     this.$store.state.onlineBasePath + this.htmlText.url
      //   );
      // } catch (e) {
      //   console.log(e);
      // }
      previewUrl({
        url: this.$store.state.onlineBasePath + this.openLibraryFile.url,
      }).then((res) => {
        window.open(res.data);
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.authorize = false;
    // this.getCheckPortfolioAuthorization();
    // this.getWordToHtml(5);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.dataAnalysisDetails {
  .wordList_title {
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
  }
  .wordList_box {
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .wordList {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .main {
    // height: calc(100vh - 150px);
    // padding-bottom: 70px;
    // overflow: auto;
    .return {
      border-top: solid 1px #e6e6e6;
      background-color: #fbfcfd;
      border-bottom: solid 1px #e6e6e6;
      display: flex;
      .back {
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 20px;

        img {
          margin-right: 15px;
          height: 15px;
        }
      }

      .operation {
        display: flex;
        align-items: center;
        .item {
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:hover {
            border-radius: 5px;
            background-color: #ecf5ff;
          }
        }
      }
    }

    .title_box {
      margin-top: 50px;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 20px 50px;
      .imgIcom {
        width: 50px;
        margin-right: 20px;
      }
      .content {
        flex: 1;
      }
      .text {
        font-size: 15px;
        font-weight: bold;
      }

      .obj {
        font-size: 12px;
        margin-top: 10px;
        color: #c3c3c3;
        display: flex;
        justify-content: space-between;
      }
    }
    .header-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 10;
      height: 30px;
      // padding: 0px 50px;
      // margin-top: 30px;
      margin: 10px 50px 0;
      // margin-bottom: 20px;
      // border-bottom: 1px solid #e6e6e6;
      .option {
        display: flex;
        align-items: center;
        color: #fe8103;
        > div {
          // margin: 0 10px;
          margin-right: 10px;
          cursor: pointer;
          font-size: 14px;
        }
      }
      .obj {
        > div {
          margin: 0 10px;
        }
      }
    }
    .file_body {
      position: relative;

      .obj {
        font-size: 12px;

        color: #c3c3c3;
        display: flex;
        justify-content: space-between;
      }
      margin: 20px 50px;
      // border: 1px solid #e6e6e6;
      box-shadow: 0 2px 10px #ccc;
      padding: 20px 50px 60px;
      position: relative;
      .TraceSourceVersion {
        position: absolute;
        top: 35px;
        color: #ff6600;
        font-size: 14px;
        right: 50px;
      }
      ::v-deep .el-tabs {
        .el-tabs__item.is-active {
          color: #ff6600;
        }
        .el-tabs__active-bar {
          background-color: #ff6600;
        }
        .el-tabs__item:hover {
          color: #ff6600;
        }
        .el-tabs__content {
          // height: 600px;
          min-height: 75vh;
          // overflow-y: auto;
        }
      }
      ::v-deep .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #e6e6e6;
      }

      .file_body_html {
        ::v-deep div {
          overflow-x: hidden;
          p {
            line-height: 1.8;
          }

          .bt-subscribe-article {
            font-size: 15px !important;
            line-height: 1.8;
          }

          span {
            font-size: 15px !important;
          }
        }
      }

      .blue {
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;

        img {
          margin-right: 10px;
        }
      }

      .comments_list {
        .kong {
          height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #888888;
          img {
            width: 150px;
            height: 150px;
            margin-top: 0px;
            margin-bottom: 20px;
          }
        }
        .item {
          margin-top: 20px;
          display: flex;
          .item_avatar {
            display: flex;
            width: 80px;
            margin-right: 30px;
            align-items: center;
            flex-direction: column;

            img {
              margin-bottom: 15px;
              width: 30px;
              height: 30px;
            }

            .name {
              font-size: 13px;
              width: 100%;
              text-align: center;
            }
          }

          .item_content {
            flex: 1;
            .item_score {
              .rate_box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .date {
                  font-size: 12px;
                  color: #888888;
                  line-height: 12px;
                }
                ::v-deep .el-rate {
                  .el-rate__item {
                    .el-rate__icon {
                      font-size: 20px;
                    }
                  }
                }
              }
            }

            .item_text {
              margin-top: 20px;
              font-size: 14px;
              line-height: 1.8;
            }

            .item_imglist {
              margin: 25px 0;
              img {
                border: 1px solid #e9e9e9;
                width: 80px;
                height: 80px;
                margin-right: 20px;
              }
            }

            .item_like {
              display: flex;
              flex-direction: row-reverse;
              img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
              .like {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #888888;
              }
            }
          }
        }
      }
    }

    .bottom_box {
      background-color: #fff;
      border-top: 1px solid #eee;
      position: sticky;
      bottom: -57px;
      width: 100%;
      display: flex;
      padding: 10px 0;
      box-sizing: border-box;
      justify-content: space-around;
      align-items: center;

      .like,
      .download {
        cursor: pointer;
        font-size: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        img {
          margin-bottom: 5px;
          width: 20px;
        }
      }

      .btn {
        cursor: pointer;
        border-radius: 25px;
        color: #fff;
        padding: 10px 25px;
        width: 60%;
        text-align: center;
        background-color: #fea802;
      }

      .bgc {
        background-color: #fe8103;
      }
    }
  }

  //授权许可
  .onlineAuthorization {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 497px;
      height: 416px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 20px 0 0;
        text-align: center;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        .el-dialog__headerbtn {
          top: 10px;
          right: 10px;
          font-size: 20px;
        }
      }
      .el-dialog__body {
        padding: 20px 30px 0 20px;
        .content {
          line-height: 24px;
          .content-item {
            display: flex;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
          }
          .notes {
            font-size: $font-size-small;
            font-family: PingFang SC;
            font-weight: 400;
            color: $color-text-gray-s;
          }
        }
        .footer {
          display: flex;
          padding: 30px 120px 0;
          justify-content: space-between;
          .footer-btn {
            cursor: pointer;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
          }
        }
      }
    }
  }
}
</style>
