<template>
  <div class="DataAnalysisIndex">
    <!-- 占位 -->
    <div class="perch" v-show="!isDetails"></div>
    <!-- 遮罩层 -->
    <div
      class="mask_layer"
      v-if="ChildrenInfoShow"
      @click="ChildrenInfoShow = false"
    ></div>
    <!-- 4级侧边栏 -->
    <div
      class="sidebar_box"
      v-show="!isDetails"
      :class="ChildrenInfoShow ? 'disflex' : ''"
    >
      <div
        class="an_btn"
        :class="ChildrenInfoShow ? 'spin' : ''"
        v-show="ChildrenInfo.list.length != 0"
        @click="
          () => {
            ChildrenInfoShow = !ChildrenInfoShow;

            if (ChildrenInfoShow) {
              if (ChildrenInfo.list.length != 0) {
                ChildrenInfo.isShow = true;
              } else if (ChildrenInfo1.list.length != 0) {
                ChildrenInfo1.isShow = true;
              } else if (ChildrenInfo2.list.length != 0) {
                ChildrenInfo2.isShow = true;
              }
            }
          }
        "
      >
        <i class="el-icon-arrow-right"></i>
      </div>

      <!-- 第一级 -->
      <div class="sidebar">
        <el-menu
          ref="elMenu"
          default-active="-2"
          :unique-opened="false"
          :collapse-transition="false"
        >
          <el-menu-item
            index="-2"
            @click="
              () => {
                pageUuid = '';
                labelId = '';
                isMark = false;
                searchValue = '';
                $refs.elMenu.close(this.stair);
                stair = 'WKlingdrssx00001';
                pageNum = 1;
                getList();
                ChildrenInfoShow = false;
                ChildrenInfo.isShow = false;
                ChildrenInfo1.isShow = false;
                ChildrenInfo2.isShow = false;
              }
            "
          >
            全部
          </el-menu-item>
          <!-- <el-menu-item
            index="-1"
            @click="
              () => {
                pageUuid = '';
                page = 1;
                searchValue = '';
                collectlist();
                isMark = true;
                labelId = '';
                ChildrenInfoShow = false;
                ChildrenInfo.isShow = false;
                ChildrenInfo1.isShow = false;
                ChildrenInfo2.isShow = false;
              }
            "
          >
            收藏模板
          </el-menu-item> -->

          <el-submenu
            :index="String(item.labelId)"
            v-for="(item, index) in sidebarList"
            @click.native="
              () => {
                isMark = false;
                pageNum = 1;
                ChildrenInfoShow = false;
                ChildrenInfo.isShow = false;
                ChildrenInfo1.isShow = false;
                ChildrenInfo2.isShow = false;
                stair = item.labelId;
                currentClickItemVip = item;
                getChildrenList(item, 2);
              }
            "
            :class="[
              item.contentNum == 0 ? 'charNumClass' : '',
              !item.isSubset ? 'isSubsetClass' : '',
            ]"
            :key="item.id"
            :title="item.conName.length > 9 ? item.conName : ''"
          >
            <template slot="title">
              {{ item.conName }}
              <el-tooltip
                effect="light"
                :content="item.remark"
                placement="right"
                popper-class="custom-popper"
              >
                <img
                  v-if="item.isVip != 1"
                  class="vipIcon"
                  :src="
                    item.isVip == 2
                      ? require('assets/images/headers/vip.png')
                      : item.isVip == 3
                      ? require('assets/images/headers/svip.png')
                      : require('assets/images/headers/plus.png')
                  "
                  @click.stop="memberRenewal(item)"
                  alt=""
              /></el-tooltip>
              <img
                v-if="item.labelTip != 0"
                :src="
                  item.labelTip == 1
                    ? require('assets/images/hot1.png')
                    : item.labelTip == 2
                    ? require('assets/images/j.png')
                    : require('assets/images/new1.png')
                "
                alt=""
              />
            </template>
            <el-menu-item
              :index="index + '-' + index1"
              v-for="(item1, index1) in item.children"
              :key="item1.id"
              :title="item1.conName.length > 8 ? item1.conName : ''"
              :class="item1.contentNum == 0 ? 'charNumClass' : ''"
              @click.native.stop="
                () => {
                  parentName = item1.conName;
                  isMark = false;
                  pageNum = 1;

                  if (ChildrenInfo.list.length != 0) {
                    ChildrenInfo.isShow = true;
                  }
                  if (isAn) {
                    ChildrenInfoShow = true;
                  } else {
                    ChildrenInfoShow = false;
                  }
                  getChildrenList(item1, 1);
                }
              "
            >
              <!-- <img class="img1" :src="$store.state.onlineBasePath + item1.iconImg" alt="" /> -->
              {{ item1.conName
              }}<img
                v-if="item1.checked == 2"
                class="img2"
                :src="require('assets/images/gouwuche1.png')"
                @click="shoppCar(item1.productId)"
                alt=""
              />
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <!-- 第二级 -->
      <div
        class="sidebar"
        :class="ChildrenInfoShow ? '' : 'absolute'"
        v-show="ChildrenInfo.isShow"
      >
        <el-menu
          default-active="-1"
          :collapse-transition="false"
          :unique-opened="false"
        >
          <el-menu-item
            index="-1"
            @click="
              () => {
                ChildrenInfo.isShow = false;
                ChildrenInfo1.isShow = false;
                ChildrenInfo2.isShow = false;
              }
            "
          >
            <i class="el-icon-arrow-left"></i>{{ ChildrenInfo.parentName }}
          </el-menu-item>
          <el-submenu
            :index="item.labelId"
            v-for="item in ChildrenInfo.list"
            @click.native="
              () => {
                getChildrenList(item, 2);
              }
            "
            :class="[
              item.contentNum == 0 ? 'charNumClass' : '',
              !item.isSubset ? 'isSubsetClass' : '',
            ]"
            :key="item.id"
            :title="item.conName.length > 7 ? item.conName : ''"
          >
            <template slot="title"> {{ item.conName }}</template>
            <el-menu-item
              :index="item1.labelId"
              v-for="item1 in item.children"
              :key="item1.id"
              :class="item1.contentNum == 0 ? 'charNumClass' : ''"
              :title="item1.conName.length > 7 ? item1.conName : ''"
              @click.native.stop="
                () => {
                  if (isAn) {
                    ChildrenInfoShow = true;
                  } else {
                    ChildrenInfoShow = false;
                  }
                  getChildrenList(item1, 3);
                }
              "
            >
              {{ item1.conName }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <!-- 第三级 -->
      <div
        class="sidebar"
        :class="ChildrenInfoShow ? '' : 'absolute'"
        v-show="ChildrenInfo1.isShow"
      >
        <el-menu
          default-active="-1"
          :collapse-transition="false"
          :unique-opened="false"
        >
          <el-menu-item
            index="-1"
            @click="
              () => {
                ChildrenInfo1.isShow = false;
                ChildrenInfo2.isShow = false;
              }
            "
          >
            <i class="el-icon-arrow-left"></i>{{ ChildrenInfo1.parentName }}
          </el-menu-item>
          <el-submenu
            :index="item.labelId"
            v-for="item in ChildrenInfo1.list"
            :title="item.conName.length > 7 ? item.conName : ''"
            @click.native="
              () => {
                getChildrenList(item, 2);
              }
            "
            :class="[
              item.contentNum == 0 ? 'charNumClass' : '',
              !item.isSubset ? 'isSubsetClass' : '',
            ]"
            :key="item.id"
          >
            <template slot="title"> {{ item.conName }}</template>
            <el-menu-item
              :index="item1.labelId"
              v-for="item1 in item.children"
              :key="item1.id"
              :class="item1.contentNum == 0 ? 'charNumClass' : ''"
              :title="item1.conName.length > 7 ? item1.conName : ''"
              @click.native.stop="
                () => {
                  if (isAn) {
                    ChildrenInfoShow = true;
                  } else {
                    ChildrenInfoShow = false;
                  }
                  getChildrenList(item1, 4);
                }
              "
            >
              {{ item1.conName }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <!-- 第四级 -->
      <div
        class="sidebar"
        :class="ChildrenInfoShow ? '' : 'absolute'"
        v-show="ChildrenInfo2.isShow"
      >
        <el-menu
          default-active="-1"
          :collapse-transition="false"
          :unique-opened="false"
        >
          <el-menu-item
            index="-1"
            @click="
              () => {
                ChildrenInfo2.isShow = false;
              }
            "
          >
            <i class="el-icon-arrow-left"></i>{{ ChildrenInfo2.parentName }}
          </el-menu-item>
          <el-submenu
            :index="item.labelId"
            v-for="item in ChildrenInfo2.list"
            :title="item.conName.length > 7 ? item.conName : ''"
            @click.native="
              () => {
                getChildrenList(item, 2);
              }
            "
            :class="[
              item.contentNum == 0 ? 'charNumClass' : '',
              !item.isSubset ? 'isSubsetClass' : '',
            ]"
            :key="item.id"
          >
            <template slot="title"> {{ item.conName }}</template>
            <el-menu-item
              :index="item1.labelId"
              v-for="item1 in item.children"
              :key="item1.id"
              :class="item1.contentNum == 0 ? 'charNumClass' : ''"
              :title="item1.conName.length > 7 ? item1.conName : ''"
              @click.native.stop="
                () => {
                  getChildrenList(item1, 5);
                }
              "
            >
              {{ item1.conName }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>

    <div
      v-show="!isDetails"
      class="mian"
      id="main"
      style="flex: 1; padding: 10px 20px 20px; box-sizing: border-box"
    >
      <OfficeNav>
        <div id="charAnalyse" class="top_analysis">
          <div
            class="top_item"
            @click="handleIndex(index)"
            v-for="(item, index) in navAnalysis"
            :key="index"
          >
            <img class="img" :src="item.img" alt="" />
            {{ item.name }}
          </div>
        </div>
        <div
          class="history"
          id="historyAnalyse"
          @click="$router.push({ name: 'DataAnalysisHistory' })"
        >
          <img
            class="img"
            :src="require('assets/images/goldenSentence/History.png')"
            alt=""
          />
          <span>历史分析报告</span>
        </div>
        <div class="history" id="dataAnalyse" @click="wordsAnalysis">
          <i class="left-item-icon icon"></i>
          <el-badge :value="sum">
            <div class="left-item-text">数据分析池</div>
          </el-badge>
        </div>
      </OfficeNav>

      <!-- 搜索 -->
      <div class="goldenSentenceIndex-search">
        <div class="search_box">
          <div class="search">
            <div class="search_left">
              <el-select
                @change="searchList"
                v-model="value"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <input
              class="inp"
              v-model="input"
              type="text"
              placeholder="精确匹配不重复的原创全文、原创段"
              @keyup.enter="searchList"
            />
            <div class="search_btn" @click="searchList">
              <!-- <i class="search_icon"></i> -->
              <img :src="require('assets/images/idiom.png')" alt="" />
              <img :src="require('assets/images/included_search.png')" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 操作 -->
      <div
        class="infocompileIndex-content-list-title"
        :class="isFixed ? 'menu' : ''"
      >
        <div class="infocompileIndex-content-list-title-left">
          <el-checkbox v-model="checked" @change="handleCheckAllChange"
            >全选添加</el-checkbox
          >
        </div>
      </div>

      <!-- 信息列 -->
      <div class="infocompileIndex-content-list-content">
        <div v-for="(v, i) in infoList" :key="i" class="content-item">
          <div class="content-item-text">
            <span @click="preview(v)" v-html="v.nodeText"></span>
            <!-- <span @click="handleAllText(v)">查看全文</span> -->
          </div>
          <!-- <div class="content-title" >
            {{ v.footNotes }}
          </div> -->
          <div class="content-item-introduce">
            <div class="introduce-checkBox" @click="handleChecked(v, i)">
              <i
                class="introduce-checkBox-icon"
                :class="v.checked == 1 ? 'checkBox-checked' : ''"
              ></i>
              <div class="introduce-checkBox-text"></div>
            </div>

            <div class="introduce-hotBox">
              <div
                class="introduce-hotBox-text"
                v-show="v.wordNum"
                style="width: 70px"
              >
                {{ v.wordNum }}字
              </div>
            </div>

            <div class="introduce-titleBox">
              <div
                @click="clickPreview(v)"
                class="introduce-titleBox-text"
                :class="v.blueTitle ? 'blue' : ''"
                v-text="v.footNotes"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 空 -->
      <div v-if="infoList.length == 0" class="kong">
        <img :src="require('assets/images/kong.png')" />
        <div>更新中</div>
      </div>

      <!-- 分页 -->
      <div class="page" v-if="infoList.length != 0">
        <el-pagination
          v-if="infoList.length != 0"
          :layout="
            value == '5'
              ? 'prev, pager, next, jumper'
              : 'prev, pager, next, jumper'
          "
          :current-page="pageNum"
          :page-size="10"
          @current-change="currentChange"
          :total="value == '5' ? 100 : total"
        >
        </el-pagination>
      </div>

      <!-- 词频分析 -->
      <el-dialog
        title="输入数据分析关键词"
        :visible.sync="wordsAnalysisDialog"
        @close="handleWordsAnalysisDialog"
        class="wordsAnalysis"
      >
        <el-alert
          title="点击启动分析，将根据您选择的数据池内容及选择/自定义关键词进行分析,关键词最多1000个。"
          type="warning"
        >
        </el-alert>
        <!-- <button @click="GetBdParticiple">获取</button> -->

        <el-collapse>
          <el-collapse-item name="1">
            <template slot="title">
              点击查看官方推荐关键词<i class="header-icon el-icon-info"></i>
            </template>
            <div class="keywords">
              <div class="title"></div>

              <div class="keywords_content">
                <div class="left">
                  <div
                    @click="citiesCLick(index)"
                    class="name"
                    :class="citiesIndex == index ? 'citiesIndex' : ''"
                    v-for="(item, index) in cities"
                    :key="index"
                  >
                    {{ index }}
                  </div>
                </div>
                <div class="right">
                  <el-checkbox
                    v-model="keywordsCheckAll"
                    border
                    @change="CheckAllChange"
                    >全选</el-checkbox
                  >
                  <el-checkbox-group
                    @change="handleCheckedCitiesChange"
                    v-model="keywordsCheckAlllist"
                  >
                    <el-checkbox
                      border
                      @change="checkboxChange"
                      v-for="(item, index) in keywordsList"
                      :key="index"
                      :label="item.item"
                      >{{ item.item }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>

        <div class="content">
          <div class="textarea">
            <el-input
              type="textarea"
              placeholder="请输入不超过1000个关键词或句，关键词或句之间，请用斜杠“/”隔开。例如：解放思想/实事求是"
              v-model="textarea"
              rows="5"
              show-word-limit
            >
            </el-input>
            <div class="zishu">
              {{ this.textarea ? this.textarea.split("/").length : 0 }}/1000
            </div>
          </div>

          <div class="btn">
            <!-- <div class="btn_box">
            <el-button>继续添加</el-button>
            <el-button>清空词频</el-button>
            <el-button @click="uploadDialog = true">上传分析文档</el-button>
          </div> -->
            <div class="start_btn">
              <el-button type="text" @click="wordsAnalysisDialog = false"
                >返回数据分析池</el-button
              >
              <div class="bian"></div>
              <el-button type="text" @click="getFontNumber"
                >启动数据分析</el-button
              >
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 数据分析 -->
      <el-dialog
        title="数据分析池"
        :close-on-click-modal="false"
        :modal="false"
        :visible.sync="dataAnalysisDialog"
        @close="handleDataAnalysisDialog"
        class="dataAnalysis"
      >
        <div class="content">
          <div class="title">
            <div class="shu"></div>

            <div class="titleinfo">
              <div class="text">数据池总字符数：{{ sum }}</div>

              <div class="text">
                选中的字符数:
                {{ pitch_onSum }}
              </div>

              <div class="text">
                字符包余量：{{
                  $store.state.user_info ? $store.state.user_info.character : 0
                }}
              </div>
            </div>
          </div>

          <div class="otherP_box">
            <div
              class="item"
              v-for="(v, i) in SelectedSum"
              :key="i"
              :class="v.isSelectedI ? 'itemHover' : ''"
              @click="clickIsSelectedI(v)"
            >
              <div class="info">
                <div class="name">
                  {{
                    v.type == 5
                      ? "原创段落"
                      : v.type == 6
                      ? "原创句子"
                      : "原创全文"
                  }}
                </div>

                <div class="quantity">
                  <div class="yuan1"></div>
                  {{ v.count }} 个
                </div>
                <div class="quantity">
                  <div class="yuan2"></div>
                  {{ v.num }} 字
                </div>
              </div>

              <img
                class="bing"
                :src="
                  v.type == 5
                    ? require('assets/images/personal-settings/bing1.png')
                    : v.type == 6
                    ? require('assets/images/personal-settings/bing2.png')
                    : require('assets/images/personal-settings/bing3.png')
                "
                alt=""
              />

              <div class="img_box" @click.stop="examineP(v)">
                <img
                  class="sou"
                  :src="require('assets/images/personal-settings/sou.png')"
                  alt=""
                  title="查看"
                />

                查看
              </div>

              <img
                class="selectedI"
                :src="require('assets/images/personal-settings/selectedI.png')"
                alt=""
              />
            </div>

            <div
              class="item"
              @click="
                () => {
                  isSelectedI = !isSelectedI;
                  pitch_onSum = isSelectedI
                    ? pitch_onSum + fileSum
                    : pitch_onSum - fileSum;
                }
              "
              :class="isSelectedI ? 'itemHover' : ''"
            >
              <div class="info">
                <div class="name">上传或选择</div>

                <div class="quantity">
                  <div class="yuan1"></div>
                  {{ tableList.length }} 个
                </div>
                <div class="quantity">
                  <div class="yuan2"></div>
                  {{ fileSum ? fileSum : 0 }} 字
                </div>
              </div>

              <img
                class="bing"
                :src="require('assets/images/personal-settings/pan.png')"
                alt=""
              />
              <img
                class="selectedI"
                :src="require('assets/images/personal-settings/selectedI.png')"
                alt=""
              />
            </div>

            <!-- <div class="item" @click="uploadFlieSource = true">
              <div class="info">
                <div class="name">上传或选择</div>

                <div class="quantity">网盘文件</div>
                <div class="quantity"></div>
              </div>

              <img
                class="bing"
                :src="require('assets/images/personal-settings/pan.png')"
                alt=""
              />

              <img
                class="selectedI"
                :src="require('assets/images/personal-settings/selectedI.png')"
                alt=""
              />
            </div> -->
          </div>

          <div class="file">
            <div class="head">
              <div class="text">
                <div class="shu"></div>
                已上传列表:
              </div>
              <div class="go_on" @click="uploadFlieSource = true">上传</div>
            </div>
            <div class="table_item" v-for="(v, i) in tableList" :key="i">
              <div class="title">
                <div class="index">{{ i + 1 }}.</div>
                <div class="name">{{ v.name }}</div>
              </div>

              <div class="date">{{ v.upDate }}</div>
              <div class="operation" @click="removeFile(v)">
                <div class="icon"></div>
                删除
              </div>
            </div>
          </div>

          <div class="btn">
            <el-button class="gray" @click="dataAnalysisDialog = false"
              >取消</el-button
            >
            <el-button
              class="blue"
              @click="
                () => {
                  if (pitch_onSum != 0) {
                    wordsAnalysisDialog = true;
                    GetBdParticiple();
                  } else {
                    $message('请选择需要分析的内容');
                  }
                }
              "
              >下一步</el-button
            >
          </div>
        </div>
      </el-dialog>

      <!-- 选择上传文件源 -->
      <el-dialog
        title="选择上传文件源"
        :visible.sync="uploadFlieSource"
        width="400px"
      >
        <div class="Source" style="text-align: center">
          <el-button
            type="primary"
            icon="el-icon-cloudy"
            @click="
              networkDiskDialog = true;
              treeIsShow = true;
              uploadFlieSource = false;
            "
            >智能网盘</el-button
          >
          <el-button
            @click="
              uploadDialog = true;
              uploadFlieSource = false;
            "
            type="primary"
            >本地文件<i class="el-icon-folder-opened el-icon--right"></i
          ></el-button>
        </div>
      </el-dialog>

      <!-- 智能网盘文件选择弹框 -->
      <el-dialog
        title="智能网盘"
        class="disk"
        :visible.sync="networkDiskDialog"
        width="500px"
      >
        <el-tree
          :props="defaultProps"
          :data="treeData"
          ref="diskTree"
          v-if="treeIsShow"
          lazy
          :load="loadChildNode"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <label style="cursor: pointer">
              <el-checkbox
                v-model="data.isSelect"
                @change="diskCheckboxSelect(data)"
                v-if="data.filetype != 0"
              >
              </el-checkbox>
              <img
                style="width: 30px; height: 30px; vertical-align: middle"
                :src="require(`@/assets/images/disk/Icons/${data.nub}.png`)"
                alt=""
              />
              <span>{{ node.label }}</span>
            </label>
          </div>
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="networkDiskDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmDiskFlieUpLoad"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 上传分析文档 -->
      <el-dialog
        title="上传分析文档"
        :close-on-click-modal="false"
        :modal="false"
        :visible.sync="uploadDialog"
        @close="handleUploadDialog"
        class="upload"
      >
        <div class="content">
          <DiskDataUpload
            v-if="uploadDialog"
            class="uploadSlot"
            :parentId="newDateId"
            :columnType="5"
            :fileType="1"
            @onFileSuccess="onFileSuccess"
            @onFileError="onFileError"
            @fileAdded="fileAdded"
            ref="uploader"
          >
          </DiskDataUpload>

          <div class="btn_box">
            <el-button type="primary" @click="addFile">确定上传</el-button>
            <el-button @click="getCHSum">关闭</el-button>
          </div>
        </div>

        <div class="content" v-show="false">
          <!-- 上传分析文档（doc、docx、wps -->
          <div class="upload" @click="uploadDialog = true">
            <img :src="require('assets/images/add.png')" alt="" />
            <span>上传分析文档（doc、docx、wps 格式) ···</span>
          </div>

          <div class="title_text">已上传列表</div>

          <!-- 词频及关联分析报告 -->
          <div class="report" v-for="item in 4" :key="item">
            <div class="title">1. 词频及关联分析报告</div>
            <div class="delete" @click="deleteDialog = true">
              <i class="icon"></i>
              删除
            </div>
          </div>

          <!-- 按钮 -->
          <div class="btn_box">
            <el-button type="primary" @click="uploadDialog = false"
              >确 定</el-button
            >
            <el-button @click="uploadDialog = false">取 消</el-button>
          </div>
        </div>
      </el-dialog>

      <!-- 免费模板库 -->
      <el-dialog
        title="添加"
        :modal="false"
        :visible.sync="freeSentencePopup"
        class="freeSentencePopup"
        top="2.5vh"
      >
        <div class="content">
          <div class="added">
            <div class="added-title">已添加语境算法引擎</div>
            <div class="added-content">
              <div
                :title="item.name"
                class="content-item"
                v-for="(item, index) in addedList"
                :key="index"
                @mouseenter="enter(item)"
                @mouseleave="leave(item)"
                @click="del(item)"
              >
                <i class="content-del-icon" v-if="item.mouser == true"></i>
                <div class="content-del-text" v-if="item.mouser == true">
                  删除
                </div>
                <div class="content-item-text" v-else>{{ item.conName }}</div>
              </div>
            </div>
          </div>
          <div class="canAdd">
            <div class="canAdd-title">
              可添加语境算法引擎
              <div class="canAdd-subTitle">点击添加语境算法引擎</div>
            </div>
            <div class="canAdd-content">
              <div
                class="canAdd-content-item"
                :title="item.name"
                v-for="(item, index) in notAddList"
                :key="index"
                @click="addLibrary(item)"
              >
                {{ item.conName }}
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 字符支付提示弹窗 -->
      <el-dialog title="字符支付" :visible.sync="dialogTips" class="tips">
        <div class="content1" v-if="curCharacter > pitch_onSum">
          <div class="content-text">
            本次分析将扣减<span>{{ pitch_onSum }}</span
            >个字符，字符包现有<span>{{ curCharacter }}</span
            >个字符，该字符将转支付给每段话、每句话的最早原创作者。
          </div>
        </div>
        <div
          class="content1"
          v-else
          :class="curCharacter < pitch_onSum ? 'active' : ''"
        >
          <i class="content-icon"></i>
          <div class="content-text">
            本次分析将扣减<span>{{ pitch_onSum }}</span
            >个字符，字符包现有<span>{{ curCharacter }}</span
            >个字符，<span>已不足。</span>
          </div>
        </div>
        <div class="footer">
          <div class="footer-btn" @click="deductInterests">确定</div>
          <div class="footer-btn" v-if="dialogTips == false > pitch_onSum">
            关闭
          </div>
          <div class="footer-btn" v-else @click="buyCharPack">购买字符包</div>
        </div>
      </el-dialog>

      <!-- 词频分析 -->
      <el-dialog
        title="词频分析"
        :close-on-click-modal="false"
        :visible.sync="realNameDialog"
        class="autonymDialog"
      >
        <div class="content">
          词频分析是对关键词的出现频次及其关联关系进行分析，形成可视化图表。
        </div>
        <!-- <div class="footer">
          <div
            class="footer-btn"
            @click="$router.push({ name: 'DataAnalysisResult' })"
          >
            分析样本
          </div>
          <div
            class="footer-btn"
            @click="$router.push({ name: 'DataAnalysisHistory' })"
          >
            历史报告
          </div>
        </div> -->
      </el-dialog>

      <!-- 查看选中列表 -->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="examineDialog"
        class="examineDialog"
      >
        <div slot="title" class="title">
          <div class="title-text">选中列表</div>
          <div class="allDelete" @click="allDelete">全部删除</div>
          <div
            class="allDelete"
            @click="
              () => {
                examineDialog = false;
                dataAnalysisDialog = false;
              }
            "
          >
            继续添加
          </div>
        </div>
        <div class="content" v-if="examineList.length">
          <div v-for="(v, i) in examineList" :key="i" class="content-item">
            <div v-if="v != null">
              <div class="content-item-text">
                <span v-html="v.nodeText"></span>
                <!-- <span @click="handleAllText(v)">查看全文</span> -->
              </div>
              <div class="content-item-introduce">
                <div class="introduce-checkBox" @click="deleteClick(v, i)">
                  <i class="introduce-checkBox-icon"></i>
                  <div class="introduce-checkBox-text"></div>
                </div>

                <div class="introduce-hotBox">
                  <div class="introduce-hotBox-text" v-show="v.wordNum">
                    {{ v.wordNum }}字
                  </div>
                </div>

                <div class="introduce-titleBox" @click="clickPreview(v)">
                  <div class="introduce-titleBox-text">
                    {{ v.footNotes }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content" v-else>
          <img class="kong" :src="require('assets/images/kong.png')" alt="" />
        </div>
      </el-dialog>
    </div>

    <div class="dataAnalysisDetails" v-show="isDetails">
      <dataAnalysisDetails ref="dataAnalysisDetails" @backClick="backClick" />
    </div>
  </div>
</template>

<script>
import {
  AnalysisStart,
  findSelectedLib,
  queryOfficicalWord,
  wordnum,
  analysisSearch,
  analysisPreselectEdit,
  analysisFrequencyAnalysis,
  analysisTextList,
  analysisDelFile,
  isView,
  analysisPreview,
  getSidebarList,
  diskFlieUpload,
  findTextUrl,
  allDelete,
  lexicalAnalysis,
  analysisGetBdParticiple,
  openLibraryFile,
  analysisPreselectInsert,
  analysisPreselectDelete2,
} from "api/infoCompile";
import { getToken } from "utils/auth";
import { getProductListGroup, setting, getlibrarylist } from "api/dropDownList";
import { getDiskAllList } from "api/disk";
// import { previewUrl } from "api/common";
import {
  checkInterestsNumber,
  deductInterestsNumber,
} from "api/traceTheSource";
import { findOftenLibraryType } from "api/goldenSentenceIndex";
import IdiomPager from "components/idiom/pager/IdiomPager";
import DiskDataUpload from "components/DataAnalysis/DiskDataUpload.vue";
import OfficeNav from "components/office-nav/OfficeNav";
import DropDownList from "components/drop-down-list/dropDownList";
import {
  added,
  notadded,
  addOftenUseLibrary,
  deleteOftenUseLibrary,
} from "api/goldenSentenceIndex.js";
import qs from "qs";
// import defineSteps from "./driver/driver.js";
// import { isFirstLogin } from "api/checking";
import dataAnalysisDetails from "./dataAnalysisDetails.vue";
import { previewUrl } from "api/common";

export default {
  name: "DataAnalysisIndex",
  components: {
    DiskDataUpload,
    OfficeNav,
    // DropDownList,
    dataAnalysisDetails,
  },

  data() {
    return {
      isMark: false,
      stair: "WKlingdrssx00001",
      isAn: false,
      ChildrenInfoShow: false,
      uploadSuccess: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      // 数据分析池
      dataAnalysisDialog: false,
      // 顶部 数组
      navAnalysis: [
        {
          name: "词频分析",
          img: require("assets/images/goldenSentence/analysis.png"),
          clickImg: require("assets/images/goldenSentence/analysis-blue.png"),
        },
        // {
        //   name: "分析样本",
        //   img: require("assets/images/goldenSentence/Analysis-model.png"),
        //   clickImg: require("assets/images/goldenSentence/Analysis-model-blue.png"),
        // },
        // {
        //   name: "历史报告",
        //   img: require("assets/images/goldenSentence/History.png"),
        //   clickImg: require("assets/images/goldenSentence/History-blue.png"),
        // },
      ],
      isChildrenID: -1,
      sidebarTtemId: -1,
      parentName: "",
      // 顶部点击的索引
      i: 0,
      // 搜索左侧数组 选中项
      value: "1",
      // 搜索左侧数组
      options: [
        {
          value: "1",
          label: "原创全文",
        },
        {
          value: "5",
          label: "原创段落",
        },
        // {
        //   value: "6",
        //   label: "原创句子",
        // },
      ],

      // 搜索内容
      input: "",

      //控制图标
      themeIconShow: false,
      // 主题显示个数
      themeListSum: 3,
      sortArr: [
        {
          title: "时间",
          sort: true,
          colorSwitch: false,
        },
        {
          title: "权威性",
          sort: true,
          colorSwitch: false,
        },
      ],
      realNameDialog: false,
      // 全选
      checked: false,
      //选中的数组
      checkarr: [],
      // 提示词
      content: "基于特定语境公开信息数据的算法引擎，智能推文的内核",
      //信息列表
      infoList: [],

      //信息排序
      sort: "desc",
      //排序类型
      sortType: "create_time",
      //分页密钥
      pageUuid: "",
      //搜索关键词
      key: "",
      //搜索类型
      findField: 1,
      pageNum: 1,
      // 上传分析文档弹窗
      uploadDialog: false,
      // 词频分析弹窗
      wordsAnalysisDialog: false,
      // 关键词
      textarea: "",

      // 二维码URL
      qrCodeurl: {},
      //定时器
      timer: null,
      // 时间戳id
      newDateId: "",
      // 字数
      sum: 0,
      //记录上传文件数量
      fileList: [],
      freeSentencePopup: false, //免费金句库
      addedList: [], //已添加list
      notAddList: [], //可添加句库
      currentClickItemVip: null,
      // 字符支付提示弹窗
      dialogTips: false,
      //字符总数
      curCharacter: 0,
      // 文件字符
      fileSum: 0,
      showSelect: true,
      tableList: [],
      total: 0,
      // 选中的文章和句子字数
      SelectedSum: [],
      isFixed: false,
      // 选中列表
      examineList: [],
      // 选中的总数
      examinetotal: 0,
      // 选中列表弹窗
      examineDialog: false,
      CHsum: 0,

      openeds: [], //默认展开的行
      sidebarList: [], //侧边栏数据
      islLine: false,
      themeId: "",
      isshow: false,

      uploadFlieSource: false, //选择上传文件源弹框控制
      networkDiskDialog: false, //智能网盘弹框

      // data: [],
      // el-tree 组件配置

      selectFileList: [], //选中文件的list
      treeData: [],
      defaultProps: {
        children: "children",
        label: "filename",
        isLeaf: "leaf",
      },
      treeIsShow: true,
      labelId: "",
      isDetails: false,
      // 选中的关键词
      keywordsList: [],
      keywordsCheckAlllist: [],

      keywordsCheckAll: false,
      cities: [],
      citiesIndex: 0,
      isSelectedI: false,
      pitch_onSum: 0,
      // 子级
      childrenList: [],

      pitchUpType: "",
      isChildrenList: false,
    };
  },
  // computed:{
  //   maxlength() {
  //     return
  //   }
  // },
  watch: {
    textarea(val) {
      this.keywordsCheckAlllist = val.split("/");
    },
  },
  methods: {
    clickIsSelectedI(v) {
      v.isSelectedI = !v.isSelectedI;
      let sum = 0;
      this.SelectedSum.forEach((item) => {
        if (item.isSelectedI) {
          sum += item.num;
        }
      });
      this.pitch_onSum = sum + (this.isSelectedI ? this.fileSum : 0);
      console.log(this.pitch_onSum);
    },
    checkboxChange(val, ele) {
      let arr = this.textarea.split("/");
      arr.forEach((item, index) => {
        if (item == ele.target._value) {
          arr.splice(index, 1);
        }
      });

      this.textarea = arr.join("/");
    },
    citiesCLick(i) {
      this.citiesIndex = i;
      this.keywordsList = this.cities[i];
      let sum = 0;

      this.keywordsCheckAlllist.forEach((item1) => {
        this.keywordsList.forEach((item) => {
          if (item.item == item1) {
            sum++;
          }
        });
      });

      this.keywordsCheckAll = sum == this.keywordsList.length ? true : false;
    },

    async GetBdParticiple() {
      let arr = [];
      this.SelectedSum.forEach((item) => {
        // console.log(item.isSelectedI);
        if (item.isSelectedI) {
          arr.push(item.type);
        }
      });
      this.isSelectedI ? arr.push(7) : "";

      const data = {
        id: this.newDateId,
        type: this.value,
        md5: this.checkarr.length != 0 ? this.checkarr.toString() : "",
        keys: this.textarea,
        types: arr.toString(),
      };
      const res = await analysisGetBdParticiple(data);

      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.cities = res.data;
      if (this.citiesIndex == 0) {
        this.citiesIndex = Object.keys(this.cities)[0];
      }

      this.keywordsList = this.cities[this.citiesIndex];
    },

    // 关键词全选
    CheckAllChange(val) {
      let arr = [];
      this.keywordsList.forEach((item) => {
        arr.push(item.item);
      });
      const newArr = Array.from(new Set(arr));

      if (val) {
        if (this.keywordsCheckAlllist.length == 0) {
          this.keywordsCheckAlllist = [...newArr];
        } else {
          this.keywordsCheckAlllist = Array.from(
            new Set([...this.keywordsCheckAlllist, ...newArr])
          );
        }

        if (this.textarea) {
          const newArr = Array.from(
            new Set([...this.textarea.split("/"), ...this.keywordsCheckAlllist])
          );
          this.textarea = newArr.join("/");
        } else {
          this.textarea = this.keywordsCheckAlllist.join("/");
        }
      } else {
        let arr1 = this.textarea.split("/");

        newArr.forEach((item) => {
          this.keywordsCheckAlllist.forEach((item1, index) => {
            if (item == item1) {
              this.keywordsCheckAlllist.splice(index, 1);
            }
          });

          arr1.forEach((item2, index2) => {
            if (item == item2) {
              arr1.splice(index2, 1);
            }
          });
        });

        this.textarea = arr1.join("/");
      }
    },
    handleCheckedCitiesChange(val) {
      // console.log(val);
      if (this.textarea) {
        const newArr = Array.from(
          new Set([...this.textarea.split("/"), ...val])
        );
        this.textarea = newArr.join("/");
      } else {
        this.textarea = val.join("/");
      }
    },
    backClick() {
      this.isDetails = false;
    },
    buyCharPack() {
      // try {
      //   let TokenObj = getToken();
      //   let access_token = "";
      //   if (TokenObj) {
      //     TokenObj = JSON.parse(TokenObj);
      //     access_token = `${TokenObj.access_token}`;
      //   }
      //   //eslint-disable-next-line
      //   OpenBrowser(
      //     `https://www.writemall.com/details/2?token=${access_token}`
      //   );
      // } catch (e) {
      //   console.log(e);
      // }
      this.$router.push("/details/2");
      this.dialogTips = false;
      // window.isifHaveBuyDialog();
    },
    // 智能网盘复选框勾选
    diskCheckboxSelect(data) {
      // 勾选时
      if (data.isSelect) {
        this.selectFileList.push(data);
        // 添加时获取下标保存在对象中
        const index = this.selectFileList.length - 1;
        data.index = index;
      } else {
        // 取消勾选时,直接获取保存在对象中的下标 , 可以不用循环匹配,
        // 使用-1替换原来的id是因为 splice 删除之后数组长度发生改变 会导致下标改变,接口传递参数时,直接过滤掉为-1的值即可
        this.selectFileList.splice(data.index, 1, -1);
      }
    },

    async loadChildNode(node, resolve) {
      // console.log(node);
      //层级为0时 表示第一层
      if (node.level == 0) {
        const res = await getDiskAllList();
        if (res.code == 200) {
          const fomartArr = this.flieTypeGetImage(res.data);
          return resolve(fomartArr);
        }
      } else {
        const data = {
          id: node.data.id,
        };
        const res = await getDiskAllList(qs.stringify(data));
        if (res.code == 200 && res.data.length != 0) {
          const fomartArr = this.flieTypeGetImage(res.data);
          return resolve(fomartArr);
        } else {
          return resolve([]);
        }
        // console.log(res);
      }
    },

    //智能网盘点击确认
    async confirmDiskFlieUpLoad() {
      // diskFlieUpload
      const format = this.selectFileList.filter((item) => {
        return item != -1;
      });

      // console.log(format);
      if (format.length === 0) {
        return this.$message.error("至少上传一个文件");
      }
      const ids = format
        .map((v) => {
          return v.id;
        })
        .join(",");
      const data = {
        ids,
        rId: this.newDateId,
      };
      const res = await diskFlieUpload(qs.stringify(data));
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "上传成功",
        });

        const data = {
          id: this.newDateId,
        };

        const result = await wordnum(data);
        // console.log(res);

        this.fileSum = result.data;
        this.sum = this.CHsum + result.data;
        if (this.isSelectedI) {
          this.pitch_onSum = this.pitch_onSum + this.fileSum;
        }

        format.forEach((item) => {
          item.name = item.filename;
          item.upDate = item.updateTime;
        });
        this.tableList = this.tableList.concat(format);

        this.networkDiskDialog = false;
        // 由于此处使用了懒加载 无法获取树组件数据源 所以直接使用重新渲染的方式来取消checkbox选中
        this.treeIsShow = false;

        this.selectFileList = [];
      } else {
        this.$message({
          type: "error",
          message: "上传失败",
        });
      }
    },

    //获取侧边栏数据
    async getSidebarList() {
      const data = {
        labelId: "",
        parentId: 7,
      };

      const res = await getlibrarylist(qs.stringify(data));

      if (res.code == 200) {
        const _temp = res.data;

        this.sidebarList = _temp;
      } else {
        this.$message({
          message: res.message,
        });
      }
    },
    async findChild(item, i) {
      // console.log(i);
      // console.log(item.labelId);
      this.pageUuid = "";
      this.labelId = item.labelId;
      this.getList(item.labelId);
      // if (!item.isSubset) {
      //   return;
      // }

      // console.log(item);
      //如果已经请求过 就不会再次请求
      if (item.children.length != 0) {
        return;
      } else {
        const res = await getlibrarylist(qs.stringify({ parentId: item.id }));
        this.$set(item, "children", res.data);
      }
    },
    async getChildrenList(item, i) {
      this.searchValue = "";
      if (item.parentId == 3) {
        this.labelId = item.labelId;
        this.remark = item.remark;
      }
      this.pageUuid = "";
      // this.labelId = item.labelId;

      this.getList(item.labelId);
      const data = {
        labelId: item.labelId,
        parentId: 3,
      };

      if (i == 1) {
        this.ChildrenInfo.isShow = true;
        if (this.ChildrenInfo.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo1.isShow = false;
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo1.list = [];
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo.parentName = item.conName;
        this.ChildrenInfo.labelId = item.labelId;
      } else if (i == 3) {
        this.ChildrenInfo1.isShow = true;
        if (this.ChildrenInfo1.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo1.parentName = item.conName;
        this.ChildrenInfo1.labelId = item.labelId;
      } else if (i == 4) {
        this.ChildrenInfo2.isShow = true;
        if (this.ChildrenInfo2.labelId == item.labelId) {
          return;
        }

        this.ChildrenInfo2.parentName = item.conName;
        this.ChildrenInfo2.labelId = item.labelId;
      }
      if (i == 2) {
        if (item.children.length != 0) {
          return;
        }
      }

      const res = await getlibrarylist(qs.stringify(data));

      if (i == 1) {
        this.ChildrenInfo.list = res.data;
      } else if (i == 3) {
        this.ChildrenInfo1.list = res.data;
      } else if (i == 4) {
        this.ChildrenInfo2.list = res.data;
      }

      if (i == 2) {
        this.$set(item, "children", res.data);
      }
    },
    // 节点选择
    handleNodeSelect(node, selectList) {
      console.log(node);
      console.log(selectList);
    },
    memberRenewal(item) {
      this.$store.commit("setLockClickText", item);
      this.$bus.$emit("buyTipsLock");
    },
    //是否显示引导
    async isShowDriver() {
      // console.log(this.$store.state.user_info);
      const data = {
        // userId:this.$store.state.user_info.id,
        pageName: "插件版数据分析",
      };
      // console.log(data);
      // const res = await isFirstLogin(data);
      // console.log(res.data);
      // if (res.data) {
      //   this.$driver.defineSteps(defineSteps);
      //   this.$driver.start();
      // }
    },
    // 查看全文
    async preview(val) {
      if (val.blueTitle) {
        let form = new FormData();
        form.append("key", val.textMd5);
        const res = await analysisPreview(form);
        window.open(res.data, "_blank");
      }
    },
    // 获取已勾选文章字数
    async getSelectedSum() {
      const res = await analysisFrequencyAnalysis();

      this.SelectedSum = res.data.map((item) => {
        item.isSelectedI = false;
        return item;
      });
      let sum = 0;
      if (res.data.length != 0) {
        res.data.forEach((item) => {
          sum += item.num;
        });
      }
      this.CHsum = sum;
      this.sum = sum;
    },
    // 删除已经上传的文件
    async removeFile(val) {
      // console.log(val);
      if (Object.hasOwn(val, "uniqueIdentifier")) {
        let form = new FormData();

        form.append("id", this.newDateId);
        form.append("md5", val.uniqueIdentifier);

        const res = await analysisDelFile(form);
        if (res.code != 200) return this.$message(res.message);
        this.tableList.forEach((f, fi) => {
          if (f.id === val.id) {
            this.tableList.splice(fi, 1);
          }
        });
        const data = {
          id: this.newDateId,
        };
        const res1 = await wordnum(data);
        // console.log(res);

        this.fileSum = res1.data;
        this.sum = this.CHsum + res1.data;
        if (this.isSelectedI) {
          this.pitch_onSum = this.pitch_onSum - this.fileSum;
        }
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1500,
        });
      } else {
        let form = new FormData();

        form.append("id", this.newDateId);
        form.append("md5", val.id);
        console.log(val);

        const res = await analysisDelFile(form);

        if (res.code != 200) return this.$message(res.message);
        this.tableList.forEach((f, fi) => {
          if (f.id === val.id) {
            this.tableList.splice(fi, 1);
          }
        });
        const data = {
          id: this.newDateId,
        };
        const res1 = await wordnum(data);
        // console.log(res);

        this.fileSum = res1.data;
        this.sum = this.CHsum + res1.data;
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1500,
        });
      }
    },
    // 查看选中列表
    async examineP(item) {
      this.pitchUpType = item.type;
      const data = {
        type: item.type,
      };
      console.log(data);
      const res = await analysisTextList(qs.stringify(data));
      // console.log(res);
      if (res.code == 2001) {
        this.examineList = [];
        this.examinetotal = 0;
        this.examineDialog = true;
        return;
      }

      this.examineList = res.data.data ? res.data.data : [];
      this.examinetotal = res.data.total;
      this.examineDialog = true;
    },
    handleDataAnalysisDialog() {
      // this.sum -= this.fileSum;
      // this.fileSum = 0;
      // this.getList();
      // this.getSelectedSum();
    },
    // 获取上传文件
    fileAdded(val) {},

    // 确定上传
    addFile() {
      this.$refs["uploader"].tableList.forEach((f, fi) => {
        const uploader = this.$refs["uploader"];
        uploader.computeMD5(f);
      });
      // this.tableList.forEach((f, fi) => {
      //   const uploader = this.$refs["uploader"];
      //   uploader.computeMD5(f);
      // });
    },

    // 获取上传文件的字符数
    async getCHSum() {
      this.uploadDialog = false;
      this.$refs["uploader"].tableList.forEach((f) => {
        if (f.completed) {
          this.tableList.push(f);
        }
      });
      const data = {
        id: this.newDateId,
      };

      const res = await wordnum(data);
      // console.log(res);

      this.fileSum = res.data;
      this.sum = this.CHsum + res.data;

      if (this.isSelectedI) {
        this.pitch_onSum = this.pitch_onSum + this.fileSum;
      }
    },

    // 扣减字符
    async deductInterests() {
      console.log(this.pitch_onSum);
      const data = {
        deductNum: this.pitch_onSum,
        type: 2,
      };
      const res = await deductInterestsNumber(data);
      if (res.code === 200) {
        this.submitOrder();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 获取字符数量
    async getFontNumber() {
      if (this.textarea == "") {
        this.$message.error("请输入分析关键词");
        return;
      }

      if (this.sum == 0) {
        this.$message.error("请上传文档或选择文章");
        return;
      }
      const data = {
        deductNum: this.sum,
        type: 2,
      };
      const res = await checkInterestsNumber(data);
      if (res.code === 200 || res.code === 7002) {
        this.curCharacter = res.data;
        this.dialogTips = true;
        console.log(this.dialogTips);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    async handleDrop(item) {
      const params = {
        id: item.id,
        groupId: 1,
      };
      await setting(params);
      this.inquire();
    },
    // 查询选中库
    async inquire() {
      const params = {
        groupId: 1,
      };

      const res = await findSelectedLib(params);
      // console.log(res);
      this.getTheme(res.data?.libraryId);
    },

    // 获取主题
    async getTheme(id) {
      const params = {
        id,
      };

      const res = await findOftenLibraryType(params);
      // console.log(res);
      if (res.code === 200) {
        this.themeList = res.data;
      } else if (res.code === 2001) {
        this.themeList = [];
      }
    },

    // 顶部点击事件
    handleIndex(index) {
      this.realNameDialog = true;
      // this.$message("功能暂未开放，敬请期待");
      // return;
      // if (index == 2) {
      //   this.$router.push({
      //     name: "DataAnalysisHistory",
      //   });
      // } else if (index == 0) {
      // this.newDateId = new Date().getTime() + this.$store.state.user_info.id;

      //   this.dataAnalysisDialog = true;
      // } else if (index == 1) {
      //   this.$router.push({
      //     name: "DataAnalysisResult",
      //   });
      // }
    },

    // 搜索btn
    searchList() {
      this.pageNum = 1;
      this.key = this.input;
      this.pageUuid = "";
      this.getList();
    },

    // 主题item点击事件
    themeClick(item, i) {
      if (item) {
        this.pageUuid = "";
        this.themeI = i;
        this.pageNum = 1;

        // this.getList(item.labelId);
      }
    },

    // 主题展开关闭
    themeControl() {
      this.themeIconShow = !this.themeIconShow;
      if (this.themeIconShow) {
        this.$refs.themeA.style.height = "130px";
        this.$refs.themeA.style.overflowY = "auto";
        this.$refs.themeB.style.height = "130px";
        this.$refs.themeB.style.overflowY = "auto";
      } else {
        this.$refs.themeA.style.height = "45px";
        this.$refs.themeA.style.overflowY = "hidden";
        this.$refs.themeB.style.height = "45px";
        this.$refs.themeB.style.overflowY = "hidden";
      }
    },

    // 全选
    async handleCheckAllChange(value) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });
        this.checked = false;
        return window.loginFun();
      }
      this.checkarr = [];

      if (value) {
        let sum = 0;
        this.infoList.map((item) => {
          if (item.checked == 0) {
            item.checked = 1;
            sum += item.wordNum;
            this.checkarr.push(
              item.nodeMd5 +
                "-" +
                (item.associationmd5 ? item.associationmd5 : 0) +
                "-" +
                item.tableName
            );
          }
        });

        let form = new FormData();
        form.append("key", this.checkarr.toString());
        form.append("findField", this.value);
        form.append("num", sum);
        form.append("labelId", this.stair);
        const res = await analysisPreselectInsert(form);
        if (res.code == 4) {
          this.$store.commit("setLockClickText", this.currentClickItemVip);
          this.checked = false;
          this.infoList.map((item) => {
            if (item.checked == 1) {
              item.checked = 0;
            }
          });
          this.$bus.$emit("buyTipsLock");
          // this.infoList.forEach((item) => {
          //   item.checked = 0;
          // });
        }
        this.getSelectedSum();
      } else {
        let sum = 0;
        this.infoList.map((item) => {
          if (item.checked == 1) {
            item.checked = 0;
            sum += item.wordNum;
            this.checkarr.push(
              item.nodeMd5 +
                "-" +
                (item.associationmd5 ? item.associationmd5 : 0) +
                "-" +
                item.tableName
            );
          }
        });

        let form = new FormData();
        form.append("key", this.checkarr.toString());
        form.append("findField", this.value);
        form.append("num", sum);

        const res = await analysisPreselectDelete2(form);
        this.getSelectedSum();
      }
    },

    async deleteClick(item) {
      let form = new FormData();
      form.append(
        "key",
        item.nodeMd5 +
          "-" +
          (item.associationmd5 ? item.associationmd5 : 0) +
          "-" +
          item.tableName
      );
      form.append("findField", this.value);
      form.append("num", item.wordNum);

      const res = await analysisPreselectEdit(form);
      this.getList();
      this.examineP({ type: this.pitchUpType });
      this.getSelectedSum();
      this.$forceUpdate();
    },
    // 样板选中
    async handleChecked(item) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      item.checked = item.checked == 1 ? 0 : 1;
      if (item.checked) {
        let form = new FormData();
        form.append(
          "key",
          item.nodeMd5 +
            "-" +
            (item.associationmd5 ? item.associationmd5 : 0) +
            "-" +
            item.tableName
        );
        form.append("findField", this.value);
        form.append("num", item.wordNum);
        form.append("labelId", this.stair);
        const res = await analysisPreselectInsert(form);
        if (res.code == 4) {
          this.$store.commit("setLockClickText", this.currentClickItemVip);
          item.checked = item.checked == 1 ? 0 : 1;
          this.$bus.$emit("buyTipsLock");
        }
      } else {
        let form = new FormData();
        form.append(
          "key",
          item.nodeMd5 +
            "-" +
            (item.associationmd5 ? item.associationmd5 : 0) +
            "-" +
            item.tableName
        );
        form.append("findField", this.value);
        form.append("num", item.wordNum);
        // form.append('labelId',this.stair)
        const res = await analysisPreselectDelete2(form);
      }

      this.getSelectedSum();
      this.$forceUpdate();
    },

    //筛选排序
    handleClick(item) {
      item.colorSwitch = !item.colorSwitch;
      item.sort = !item.sort;
      if (item.title == "时间") {
        if (item.sort) {
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "create_time";
          this.getList();
        } else {
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "create_time";
          this.getList();
        }
      }
      if (item.title == "权威性") {
        if (item.sort) {
          this.pageNum = 1;
          this.pageUuid = "";
          this.sort = "desc";
          this.sortType = "authoritative";
          this.getList();
        } else {
          this.pageNum = 1;
          this.pageUuid = "";
          this.sort = "asc";
          this.sortType = "authoritative";
          this.getList();
        }
      }
    },

    // 词频分析
    wordsAnalysis() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.dataAnalysisDialog = true;
    },

    //获取信息
    async getList(id) {
      let data;
      if (id) {
        data = {
          page: this.pageNum,
          key: this.key,
          pageUuid: this.pageUuid,
          sort: this.sort,
          labelId: id ? id : this.labelId,

          sortType:
            this.value == 1
              ? this.sortType == "create_time"
                ? "time_sort"
                : this.sortType
              : this.sortType,
          findField: this.value,
        };
      } else {
        data = {
          page: this.pageNum,
          key: this.key,
          labelId: id ? id : this.labelId,
          sort: this.sort,
          pageUuid: this.pageUuid,
          sortType:
            this.value == 1
              ? this.sortType == "create_time"
                ? "time_sort"
                : this.sortType
              : this.sortType,
          findField: this.value,
        };
        this.themeList = [];
      }

      let res =
        this.value == 1
          ? await queryOfficicalWord(qs.stringify(data))
          : await analysisSearch(qs.stringify(data));
      if (res.code == 200) {
        this.infoList = res.data?.data ? res.data.data : [];
        this.total =
          this.value == 1
            ? res.data?.totalNum
              ? res.data.totalNum
              : 0
            : res.data?.total
            ? res.data.total
            : 0;

        // this.pageCount = Math.ceil(this.total / 10);
        this.pageUuid = res.data.page_uuid;
        // 手动添加 ischecked判断是否选中

        let num = 0;

        this.infoList.forEach((item) => {
          if (item.checked) {
            num++;
          }
          // item.labelId = item.labelId.split("-")[0];
        });

        if (num == this.infoList.length) {
          if (num == 0) {
            this.checked = false;
          } else {
            this.checked = true;
          }
        } else {
          this.checked = false;
        }
        // this.rdq(this.infoList);
      } else {
        this.$message.error(res.message);
      }
    },
    // 查看全文
    async rdq(arr) {
      const res = await isView();
      let arr2 = [];
      res.data.library.forEach((item) => {
        arr2.push(item.labelId);
      });
      res.data.single.forEach((item) => {
        arr2.push(item.md5);
      });

      arr.map((item) => {
        arr2.map((item1) => {
          if (item.labelId == item1) {
            return (item.blueTitle = true);
          } else if (item.textMd5 == item1) {
            return (item.blueTitle = true);
          } else {
            return (item.blueTitle = false);
          }
        });
      });
      // console.log(arr);
      this.infoList = arr;
      this.$forceUpdate();
    },
    // 通用中文语境库
    initialization() {
      this.pageNum = 1;
      this.key = "";
      this.pageUuid = "";
      this.sort = "";
      this.sortType = "create_time";
      this.value = 1;
      console.log("触发初始化的方法");
      this.getList();
    },
    // 打开添加弹窗
    openAddPopup() {
      this.getNotaddedList();
      this.getaddedList();
      this.freeSentencePopup = true;
      console.log("触发打开添加弹窗方法");
    },

    // 获取已添加句库list
    async getaddedList() {
      const params = {
        groupId: 1,
      };
      const res = await added(params);

      if (res.code === 200) {
        this.addedList = res.data;
        this.addedList.map((item) => {
          item.mouser = false;
        });
      } else if (res.code === 2001) {
        this.addedList = res.data;
      }
    },
    // 获取可添加句库
    async getNotaddedList() {
      const params = {
        groupId: 1,
      };
      const res = await notadded(params);

      if (res.code === 200) {
        this.notAddList = res.data;
      }
    },
    // 添加模板库
    async addLibrary(item) {
      let formData = new FormData();
      formData.append("id", item.id);
      // formData.append("libType", item.libType);
      const res = await addOftenUseLibrary(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "添加成功！",
        });
        this.getNotaddedList();
        this.getaddedList();
      }
    },
    // 删除模板库
    async del(item) {
      let formData = new FormData();
      formData.append("id", item.id);
      const res = await deleteOftenUseLibrary(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        this.getNotaddedList();
        this.getaddedList();
      }
    },

    // 鼠标移入事件
    enter(item) {
      item.mouser = true;
      this.$forceUpdate();
    },
    // 鼠标移出事件
    leave(item) {
      item.mouser = false;
      this.$forceUpdate();
    },

    // 跳转全文页
    handleAllText(item) {
      this.$router.push({
        name: "AllText",
        query: {
          id: item.docmd5,
          fileUrl: item.fileUrl,
        },
      });
    },
    libraryItme(id) {
      this.getList();
      this.inquire();
    },

    // 分页
    // page改变触发
    currentChange(cur) {
      this.$nextTick(() => {
        let PageId = document.querySelector("#main");

        PageId.scrollTop = 0;
      });
      window.document.documentElement.scrollTop = 0;
      this.pageNum = cur;
      this.getList();
    },

    // 上传分析文档弹窗关闭
    handleUploadDialog() {},

    // 词频分析弹窗关闭
    handleWordsAnalysisDialog() {
      this.textarea = "";
      this.keywordsCheckAlllist = [];
    },

    onFileSuccess(file) {
      if (!file.isFolder) {
        this.fileList.push(1);
      }
    },
    onFileError(file) {
      if (!file.isFolder) {
        this.fileList.push(1);
      }
    },

    // 购买商品
    async submitOrder() {
      // this.paymentPopup = true;
      let arr = [];
      this.SelectedSum.forEach((item) => {
        console.log(item.isSelectedI);
        if (item.isSelectedI) {
          arr.push(item.type);
        }
      });
      this.isSelectedI ? arr.push(7) : "";

      const data = {
        id: this.newDateId,
        type: this.value,
        md5: this.checkarr.length != 0 ? this.checkarr.toString() : "",
        keys: this.textarea,
        types: arr.toString(),
      };

      // this.dialogTips = true;
      // this.getFontNumber();
      const res = await AnalysisStart(data);
      if (res.code == 200) {
        this.$router.push({
          name: "DataAnalysisResult",
          query: {
            id: res.data,
          },
        });
      } else {
        this.$message.error(res.message);
      }
    },
    async clickPreview(item) {
      if (this.labelId == null || this.labelId == "undefined") {
        this.labelId = "WKlingdrssx00001";
      }
      const data = {
        md5Label: item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
        originalId: item.userId,
        templateId: "",
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      // const loading = this.$createLoading("#infocompileIndex");
      try {
        const resdata = await openLibraryFile(qs.stringify(data));
        loading.close();

        this.$refs["dataAnalysisDetails"].openLibraryFile = {
          ...resdata.data.obj,
          html: resdata.data.html,
          payStatus: resdata.data.status,
          url: resdata.data.url,
          viptip: resdata.data.viptip,
          author: resdata.data.author,
          currentClickItemVip: this.currentClickItemVip,
        };
        window.document.documentElement.scrollTop = 0;
        this.isDetails = true;
      } catch (e) {
        loading.close();
      }
    },
    // 监听滚动条
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      // 是否粘性布局
      if (scrollTop > 175) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    // 弹框删除
    async allDelete() {
      const data = {
        type: this.pitchUpType,
      };
      const res = await allDelete(qs.stringify(data));
      if (res.code == 200) {
        this.examineP({ type: this.pitchUpType });
      }
      this.getList();
      this.getSelectedSum();
    },
    //根据文件类型添加图标
    flieTypeGetImage(arr) {
      arr.forEach((item) => {
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = "01";
            } else {
              item.nub = "03";
            }
            break;
          case 1:
            item.nub = "04";
            break;
          case 2:
            item.nub = "07";
            break;
          case 3:
            item.nub = "08";
            break;
          case 4:
            item.nub = "05";
            break;
          case 5:
            item.nub = "06";
            break;
          case 6:
            item.nub = "07";
            break;
          case 7:
            item.nub = "04";
            break;
          case 8:
            item.nub = "05";
            break;
          case 9:
            item.nub = "14";
            break;
          case 10:
            item.nub = "14";
            break;
          case 11:
            item.nub = "09";
            break;
          case 12:
            item.nub = "09";
            break;
          case 13:
            item.nub = "09";
            break;
          case 14:
            item.nub = "10";
            break;
          case 15:
            item.nub = "01";
            break;
          case 16:
            item.nub = "09";
            break;
          case 17:
            item.nub = "12";
            break;
          case 18:
            item.nub = "12";
            break;
          case 19:
            item.nub = "13";
            break;
          case 20:
            item.nub = "11";
            break;
          case 21:
            item.nub = "15";
            break;
          case 22:
            item.nub = "11";
            break;
          case 23:
            item.nub = "15";
            break;
        }
        if (item.filetype != 0) {
          this.$set(item, "leaf", true);
          this.$set(item, "isSelect", false);
        }
      });
      return arr;
    },
  },

  mounted() {
    // this.$refs["dropList"].getLibGroup(1);
    window.addEventListener("scroll", this.handleScroll);
    window.initialization = this.initialization;
    window.openAddPopup = this.openAddPopup;
    window.libraryItme = this.libraryItme;
    this.isShowDriver();
  },

  created() {
    this.newDateId = new Date().getTime() + Math.floor(Math.random() * 10 + 1);

    this.getSelectedSum();
    this.getList();
    // this.inquire();
    this.getSidebarList();
    // if (!getToken("auth_token")) {
    //   this.showSelect = false;
    // } else {
    //   this.showSelect = true;
    // }
    // this.getDiskTreeList();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tooltip.item {
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-tooltip__popper {
  background: #fff !important;
  color: #ff6900 !important;
  border: 1px solid #ff6900 !important;

  .popper__arrow::after {
    border-bottom-color: #ff6900 !important;
  }
}
</style>

<style lang="scss" scoped>
.aa {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50px;
}
.pitchOn {
  ::v-deep .el-submenu__title {
    box-sizing: border-box;
    color: #ff6900;
    background-color: #ffbf6914;
    border-right: #ff6900 4px solid;
  }
}

.mask_layer {
  position: fixed;
  left: 0;
  top: 51px;
  width: 100%;
  height: calc(100% - 51px);
  opacity: 0.2;
  background: #000;
  z-index: 2000;
}
.absolute {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0px;
}

.DataAnalysisIndex {
  // border-top: solid 1px #e6e6e6;
  width: 100%;
  display: flex;
  font-size: 14px;
  // padding: 0 30px 0;
  .perch {
    min-width: 210px;
    height: calc(100vh - 51px);
  }

  .disflex {
    display: flex;
  }

  .sidebar_box {
    position: absolute;
    left: 0;
    z-index: 2001;
    background-color: #fff;

    .an_btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      z-index: 10000;
      background-color: #3683f263;
      color: #fff;
      transform: translate(-50%);
      right: -40px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
    }

    .spin {
      transform: translate(-50%) rotate(180deg);
    }
  }

  .sidebar {
    width: 210px;
    // border-right: solid 1px #e6e6e6;
    height: calc(100vh - 50px);
    overflow: auto;
    .el-menu {
      border-right: 0px;
      .isSubsetClass {
        ::v-deep .el-submenu__title {
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }
      .charNumClass {
        ::v-deep .el-submenu__title {
          cursor: not-allowed;
          color: #ccc;
        }
        cursor: not-allowed;
        color: #ccc;
      }
      .submenu {
        ::v-deep .el-submenu__title {
          cursor: not-allowed;
          color: #ccc;
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }

      .elMenuItem {
        cursor: not-allowed;
        color: #ccc;
      }
      .is-active {
        color: #ff6900;
        border-right: #ff6900 4px solid;
        background: #ffbf6914;
      }
      .el-menu-item {
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        -webkit-transition: none;
        transition: none;
        &:hover {
          color: #ff6900;

          border-right: #ff6900 4px solid;
        }
      }
    }
    ::v-deep .el-submenu {
      .el-submenu__title {
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        align-items: center;
        white-space: nowrap;

        .vipIcon {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 40px;
          margin-left: 0px;
        }

        img {
          width: 13px;

          margin-left: 5px;
        }
        &:hover {
          color: #ff6900;
        }
      }
      .el-menu {
        .el-menu-item {
          &:hover {
            color: #ff6900;
            border-right: #ff6900 4px solid;
          }
          height: 25px;
          line-height: 25px;
          font-size: 14px;
          -webkit-transition: none;
          transition: none;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          align-items: center;
          white-space: nowrap;
          min-width: 190px;
        }
        .is-active {
          background-color: #ffbf6914;
          border-right: #ff6900 4px solid;
        }
      }
    }

    .img1 {
      width: 20px;
      // margin-right: 10px;
    }
    .img2 {
      width: 20px;
      margin-left: 10px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  .office-nav {
    @include flex-start;
    // width: 100vw;
    // margin-left: -30px;
    min-width: auto !important;
    ::v-deep .dropDownList .el-dropdown-link {
      font-size: 13px;
    }
    .history {
      @include flex-start;
      margin-left: 20px;
      cursor: pointer;
      > span {
        margin-left: 5px;
        font-size: 13px;
      }
    }
  }
  ::v-deep .el-badge__content.is-fixed {
    position: absolute;
    top: 6px;
    right: 10px;
  }
  .theme_box {
    // padding: 10px 0 0;
    .title {
      font-weight: bold;
    }
    .theme_list {
      // margin: 15px 0 0;
      display: flex;
      flex-wrap: wrap;
      font-size: 13px;
      position: relative;
      .theme_item {
        cursor: pointer;
        padding: 5px;
        margin: 5px;

        &:hover {
          color: #ff6900;
          background-color: #ffbf6914;
          border-radius: 5px;
        }
      }

      .all {
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 5px;
      }

      .blue {
        color: #ff6900;
        background-color: #ffbf6914;
        border-radius: 5px;
      }
      .selected {
        color: #ff6900;
        background-color: #ffbf6914;
        border-radius: 5px;
      }
    }
  }
  //  顶部
  .top_analysis {
    display: flex;
    align-items: center;
    margin-left: 20px;
    .top_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      .img {
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
    }

    .blue {
      color: #ff6900;
    }
  }
  // 搜索
  .goldenSentenceIndex {
    &-search {
      margin: 20px 0 0;
      // 搜索
      .search_box {
        .search {
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          // padding: 5px 5px 5px 0;

          border-radius: 3px;
          border: 1px solid #ff6900;

          .search_left {
            .el-select {
              ::v-deep .el-input {
                .el-input__inner {
                  width: 120px;
                  border: 0px;
                }
              }
            }
          }

          .inp {
            height: 26px;
            border-left: 1px solid #e4e4e4;
            padding-left: 20px;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-default;
            flex: 1;
          }
          .search_btn {
            // width: 40px;
            height: 40px;
            padding: 0 10px;
            background: #ff6900;
            // @include backgroundGroup("~assets/images/idiom.png");
            @include flex-center();
            // border-radius: 0 5px 5px 0;
            cursor: pointer;
            > img:first-child {
              width: 52px;
              height: 22px;
              margin: 0 10px;
            }
            // .search_icon {
            //   width: 17px;
            //   height: 17px;
            //   @include backgroundGroup("~assets/images/idiom.jpg");
            // }
          }
        }
      }
    }
  }

  // 主题
  .list-theme {
    position: relative;
    height: 45px;
    line-height: 45px;
    background: #f8fafc;
    border-radius: 5px;
    overflow-y: hidden;
    display: flex;
    &-item {
      display: flex;
      position: relative;
      margin-left: 20px;
      top: 0px;
      &-text {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        width: 50px;
      }
      .list-theme-img {
        width: 13px;
        height: 13px;
        margin-left: 6px;
        transform: translateY(15px);
      }
    }
    &-content {
      flex: 1;
      display: flex;
      margin-left: 30px;
      height: 45px;
      flex-wrap: wrap;
      line-height: 45px;
      &-item {
        font-size: 14px;
        width: 115px;
        margin-right: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        cursor: pointer;
        @include ellipsis();
        &:hover {
          color: #ff6900;
        }
      }
    }
    &-icon {
      position: absolute;
      right: 30px;
      top: 15px;
      height: 14px;
      width: 14px;
      cursor: pointer;
    }
    &-icon-up {
      @include backgroundGroup(
        "../../assets/images/goldenSentence/theme-up.png"
      );
    }
    &-icon-down {
      @include backgroundGroup(
        "../../assets/images/goldenSentence/theme-down.png"
      );
    }
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    z-index: 20;
    padding: 20px 30px !important;
    border-bottom: 2px solid #efefef;
  }

  // 操作
  .infocompileIndex-content-list {
    &-title {
      padding: 20px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;

        ::v-deep .el-checkbox {
          margin-right: 20px;
          .el-checkbox__inner {
            width: 16px;
            height: 16px;

            &::after {
              left: 5px;
              top: 2px;
            }
          }

          .el-checkbox__label {
            font-size: $font-size-medium;

            font-weight: 500;
            color: $color-text-black;
          }
        }

        .left-item {
          display: flex;
          margin-left: 40px;
          cursor: pointer;

          ::v-deep .el-badge__content.is-fixed {
            background-color: #fff;
            color: #ff6900;
            top: 50%;
            right: 0px;
          }

          .left-item-icon {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }

          .icon {
            @include backgroundGroup(
              "~assets/images/goldenSentence/words-analysis.png"
            );
          }
        }
      }

      &-right {
        display: flex;
        align-items: center;

        .right-item {
          display: flex;
          margin-right: 40px;
          cursor: pointer;

          .right-item-text {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }

          .right-item-icon {
            width: 9px;
            height: 13px;
            margin-left: 6px;
          }

          .up {
            @include backgroundGroup("~assets/images/up.png");
          }

          .down {
            @include backgroundGroup("~assets/images/down.png");
          }

          .fontColor {
            color: #ff6900;
          }
        }

        .right-item:last-of-type {
          margin-right: 0px;
        }
      }
    }
  }

  // 信息
  .infocompileIndex-content-list {
    &-content {
      .content-item {
        position: relative;

        .content-item-icon {
          position: absolute;
          left: 0px;
          top: 10px;
          width: 34px;
          height: 30px;
          display: none;
        }

        .reprint {
          @include backgroundGroup("~assets/images/info/reprint.png");
        }

        .starting {
          @include backgroundGroup("~assets/images/info/starting.png");
        }

        .content-item-text {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 26px;
          padding: 20px 0 0;

          // span:last-of-type {
          //   color: #3683f2;
          //   cursor: pointer;
          //   text-decoration: underline;
          // }

          .fontBlue {
            color: #ff6900;
          }
        }
        .content-title {
          font-size: 13px;
          color: gray;
          font-family: "PingFang SC";
          cursor: pointer;
        }
        .content-title:hover {
          color: #ff6900;
        }
        .content-item-introduce {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          padding-bottom: 20px;
          padding-top: 20px;
          border-bottom: 1px solid #efefef;

          .introduce-checkBox {
            display: flex;
            align-items: center;
            cursor: pointer;

            .introduce-checkBox-icon {
              width: 17px;
              height: 16px;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: url("~assets/images/info/checkBox.png");
              margin-right: 5px;
            }

            .checkBox-checked {
              background-image: url("~assets/images/info/checkBox-checked.png");
            }
          }

          .introduce-hotBox {
            margin-left: 18px;
            display: flex;
            align-items: center;
          }

          .introduce-titleBox-text {
            // width: 400px;
            // margin-left: 24px;
            cursor: pointer;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }
          .introduce-titleBox-text:hover {
            color: #ff6900;
          }
          .blue {
            color: #ff6900;
          }
        }
      }
    }
  }

  // 空
  .kong {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      margin-bottom: 20px;
      width: 150px;
      height: 150px;
    }
  }

  // 分页
  .page {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }

  // 词频分析弹窗
  .wordsAnalysis {
    ::v-deep .el-dialog {
      margin-top: 8vh !important;
      width: 680px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 800;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 10px 20px 5px;

        .el-collapse {
          .el-collapse-item {
            .el-collapse-item__header {
              color: #ff6900;
              &:hover {
                background-color: #ffbf6914;
              }
              .header-icon {
                margin-left: 10px;

                &:before {
                  color: #ff6900;
                }
              }
            }
          }
        }

        .keywords {
          position: relative;

          .title {
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-checkbox {
              margin: 5px;
              padding: 5px 10px;
              height: 30px;
            }
          }

          .keywords_content {
            display: flex;

            .left {
              margin-right: 15px;
              height: 380px;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }
              .name {
                cursor: pointer;
                padding: 10px 15px;

                &:hover {
                  background-color: #f1f1f1;
                  color: #ff6900;
                }
              }

              .citiesIndex {
                color: #ff6900;
                border-right: 3px solid #ff6900;
              }
            }

            .right {
              height: 380px;
              overflow-y: auto;
              flex: 1;
              display: flex;

              .el-checkbox {
                margin: 5px 10px 10px 0;
                padding: 5px 10px;
                height: 30px;
              }
              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }
              .el-checkbox-group {
                .el-checkbox {
                  margin: 5px 10px 10px 0;
                  padding: 5px 10px;
                  height: 30px;
                }
              }
            }
          }
        }

        .content {
          .text {
            line-height: 1.5;
            text-indent: 2em;
            font-size: 12px;
            color: #666666;
          }

          .title {
            margin: 20px 0;
            font-weight: 800;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {
              span {
                &:nth-child(1) {
                  color: red;
                }
                &:nth-child(2) {
                  font-weight: 400;
                }
              }
            }

            .btn {
              margin-top: 10px;

              .el-button {
                font-size: 12px;
              }
            }
          }

          .textarea {
            position: relative;
            .el-textarea__inner {
              resize: none;
              font-family: PingFang SC;
            }

            .zishu {
              color: #909399;
              background: #fff;
              position: absolute;
              font-size: 12px;
              bottom: 5px;
              right: 10px;
            }
          }

          .btn {
            margin-top: 10px;
            .btn_box {
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              .el-button {
                color: #ff6900;
                border: 1px solid #ff6900;

                &:nth-child(2) {
                  color: #999999;
                  border: 1px solid #999999;
                }
              }
            }

            .start_btn {
              display: flex;
              align-items: center;
              .el-button {
                padding: 15px;
                width: 100%;
                font-size: 16px;
              }
              .bian {
                height: 20px;
                width: 1px;
                background-color: #e8e8e8;
              }
            }
          }
        }
      }
    }
  }
  // 数据分析池
  .dataAnalysis {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 680px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 800;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        .content {
          .title {
            display: flex;
            align-items: center;

            .shu {
              margin-right: 10px;
              border-radius: 12.5px;
              background-color: #ff6900;
              width: 4px;
              height: 20px;
            }

            .titleinfo {
              font-family: PingFang SC;
              flex: 1;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }

          .otherP_box {
            display: flex;
            padding: 20px 0px;
            justify-content: space-around;

            .itemHover {
              cursor: pointer;
              border: 1px solid #4587ff !important;
              .selectedI {
                display: block !important;
              }
            }
            .item {
              &:hover {
                cursor: pointer;
                border: 1px solid #ff6900;
                // .selectedI {
                //   display: block;
                // }
              }
              border: 1px solid #e6efff;
              padding: 15px 20px;
              position: relative;
              display: flex;
              align-items: center;

              box-sizing: border-box;

              .info {
                margin-right: 20px;
                .name {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  margin-bottom: 15px;
                }

                .quantity {
                  margin: 5px 0;
                  font-size: 12px;
                  color: #666666;
                  display: flex;
                  align-items: center;

                  .yuan2 {
                    margin-right: 5px;
                    width: 6px;
                    height: 6px;
                    background-color: #ff6900;
                    border-radius: 50%;
                  }
                  .yuan1 {
                    margin-right: 5px;
                    width: 6px;
                    height: 6px;
                    background: #5e50e8;
                    border-radius: 50%;
                  }
                }
              }

              .bing {
                transform: translateY(-10px);
                width: 45px;
                height: 45px;
              }

              .img_box {
                position: absolute;
                bottom: 3px;
                right: 3px;
                color: #ff6900;
                font-size: 12px;

                display: flex;
                align-items: center;
              }
              .sou {
                margin-right: 3px;
                width: 15px;
                height: 15px;
              }

              .selectedI {
                display: none;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 30px;
                height: 30px;
              }
            }
          }

          .file {
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #f7f7f7;
            .head {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .text {
                display: flex;
                align-items: center;
                .shu {
                  margin-right: 10px;
                  border-radius: 12.5px;
                  background-color: #ff6900;
                  width: 4px;
                  height: 20px;
                }
              }

              .go_on {
                background-color: #ff6900;
                width: 30px;
                padding: 5px 20px;
                border-radius: 15px;
                text-align: center;
                background-size: 100% 100%;
                cursor: pointer;
                color: #fff;
              }
            }
            .table_item {
              padding: 20px 0 0;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              font-family: PingFang SC;

              .title {
                display: flex;
                .index {
                  margin-right: 5px;
                }

                .name {
                  width: 320px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  word-break: break-all;
                }

                .error {
                  color: #ce2e2e;
                }
                .success {
                  color: rgb(8, 160, 8);
                }
              }

              .operation {
                cursor: pointer;
                display: flex;
                .icon {
                  margin-right: 10px;
                  width: 14px;
                  height: 14px;
                  background: url("~assets/images/goldenSentence/analysis-delete.png")
                    no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            .operation:hover {
              color: #ff6900;
              .icon {
                background: url("~assets/images/goldenSentence/analysis-delete-blue.png");
              }
            }
          }

          .btn {
            margin-top: 20px;
            display: flex;
            flex-direction: row-reverse;

            .blue {
              border: #ff6900 1px solid;
              color: #ff6900;
              margin-right: 20px;
            }

            .gray {
              color: #999999;
            }
          }
        }
      }
    }
  }

  .disk {
    ::v-deep .el-dialog .el-dialog__body {
      max-height: 500px;
      overflow-y: auto;
    }
  }
  // 上传分析文档弹窗
  .upload {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 800;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        .content {
          // 上传分析文档（doc、docx、wps
          .upload {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            img {
              margin-right: 12px;
              width: 16px;
              height: 16px;
            }

            span {
              font-size: 14px;
              color: #ff6900;
            }
          }

          .title_text {
            margin-bottom: 20px;
            font-size: 12px;
            color: #999999;
          }

          // 词频及关联分析报告
          .report {
            margin: 20px 0;
            display: flex;
            justify-content: space-between;

            .delete {
              cursor: pointer;
              display: flex;
              align-items: center;
              .icon {
                width: 16px;
                height: 16px;
                background: url("~assets/images/goldenSentence/analysis-delete.png")
                  100% no-repeat;
                margin-right: 12px;
              }

              &:hover {
                .icon {
                  background: url("~assets/images/goldenSentence/analysis-delete-blue.png")
                    100% no-repeat;
                }

                color: #ff6900;
              }
            }
          }

          // 按钮
          .btn_box {
            position: absolute;
            bottom: 20px;
            right: 20px;
            margin-top: 30px;
            text-align: right;
          }
        }
      }
    }
  }

  // 免费金句库
  .freeSentencePopup {
    ::v-deep .el-dialog {
      width: 525px;
      height: 450px;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.24);
      .el-dialog__header {
        padding: 0;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-dialog__title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .el-dialog__headerbtn {
          top: 8px;
          right: 11px;
          font-size: 18px;
        }
      }
      .el-dialog__body {
        padding: 12px 0 0;
        margin: 0px 10px;
        border-top: 1px solid #f7f7f7;
        height: 390px;
        overflow-y: auto;
        .content {
          .added {
            padding-bottom: 18px;
            border-bottom: 1px solid #f7f7f7;
            .added-title {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
            }
            .added-content {
              margin-top: 19px;
              display: flex;
              flex-wrap: wrap;
              .content-item {
                width: 120px;
                margin-right: 6px;
                margin-bottom: 10px;
                height: 30px;
                background: #f3f3f3;
                border-radius: 5px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                .content-del-icon {
                  width: 11px;
                  height: 12px;
                  @include backgroundGroup(
                    "../../assets/images/goldenSentence/del-icon.png"
                  );
                  margin-right: 4px;
                }
                .content-del-text {
                  font-size: 13px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #4587ff;
                }
                .content-item-text {
                  padding: 0 10px;
                  @include ellipsis();
                }
              }
            }
          }
          .canAdd {
            margin-top: 18px;
            .canAdd-title {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
              display: flex;
              align-items: center;
              .canAdd-subTitle {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-left: 9px;
              }
            }
            .canAdd-content {
              margin-top: 19px;
              display: flex;
              flex-wrap: wrap;
              .canAdd-content-item {
                width: 100px;
                margin-right: 6px;
                margin-bottom: 10px;
                height: 30px;
                background: #f3f3f3;
                border-radius: 5px;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
                @include ellipsis();
                padding: 0 10px;
                &:hover {
                  background: #f4f8ff;
                  color: #ff6900;
                }
              }
            }
          }
        }
      }
    }
  }

  //溯源支付弹窗
  .tips {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 340px;
      min-height: 170px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 15px 39px;
        .content1 {
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
          line-height: 26px;
          display: flex;
          word-break: break-all;
          span {
            color: #eb441e;
          }
          .content-icon {
            width: 31px;
            height: 18px;
            @include backgroundGroup("../../assets/images/del-error.png");
            transform: translateY(4px);
            margin-right: 9px;
          }
        }
        .active {
          margin-top: 20px;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 28px;
          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }

  // 选中列表
  .examineDialog {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 600px;
      min-height: 170px;
      max-height: 500px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 25px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .title {
          display: flex;
          padding: 0 0 0 10px;
          width: 40%;
          .title-text {
            // font-size: $font-size-small;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: $color-text-gray;
          }
          .allDelete {
            // font-size: $font-size-small;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ff6900;
            cursor: pointer;
            margin-left: 20px;
          }
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 15px 39px;
        overflow: auto;
        .content {
          .kong {
            width: 150px;
            height: 150px;
            margin: 15vh auto 0;
          }
          height: 430px;
          .content-item {
            position: relative;

            .content-item-icon {
              position: absolute;
              left: 0px;
              top: 10px;
              width: 34px;
              height: 30px;
              display: none;
            }

            .reprint {
              @include backgroundGroup("~assets/images/info/reprint.png");
            }

            .starting {
              @include backgroundGroup("~assets/images/info/starting.png");
            }

            .content-item-text {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 26px;
              padding: 20px 0 30px;

              // span:last-of-type {
              //   color: #3683f2;
              //   cursor: pointer;
              //   text-decoration: underline;
              // }

              .fontBlue {
                color: #ff6900;
              }
            }

            .content-item-introduce {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
              padding-bottom: 20px;
              border-bottom: 1px solid #efefef;

              .introduce-checkBox {
                display: flex;
                align-items: center;
                cursor: pointer;

                .introduce-checkBox-icon {
                  width: 17px;
                  height: 17px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-image: url("~assets/images/delete-blue.png");
                  margin-right: 5px;
                }

                .checkBox-checked {
                  background-image: url("~assets/images/info/checkBox-checked.png");
                }
              }

              .introduce-hotBox {
                margin-left: 18px;
                display: flex;
                align-items: center;
              }

              .introduce-titleBox-text {
                cursor: pointer;
                width: 400px;
                margin-left: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .introduce-titleBox-text:hover {
                color: #ff6900;
              }
            }
          }
        }
      }
    }
  }
}

.autonymDialog {
  ::v-deep .el-dialog {
    margin-top: 20vh !important;
    width: 340px;
    min-height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 0 30px 30px;
      .content {
        margin: 40px 0;
        text-align: center;
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        word-break: break-all;
        span {
          color: $color-bg-heightlight;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .footer-btn {
          font-size: 18px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          cursor: pointer;
        }
      }
    }
  }
}

.dataAnalysisDetails {
  // position: absolute;
  left: 0;
  top: 25px;
  background: #fff;
  width: 100%;
}
</style>
