import { requestApi } from "utils/request";
export const mergeFile = (data) => {
  let option = {};
  if (data.nuber == 26) {
    option.url = "/uploader/copyFile";
  } else {
    option.url = "/uploader/mergeFile";
  }

  option.isJson = true;
  option.data = JSON.stringify(data);
  option.method = "post";

  return requestApi(option);
};
