import axios from "axios";
import store from "store/index";
import { getToken } from "@/utils/auth";

const myAxios = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${process.env.VUE_APP_BASE_API}/mqapi`
      : "/mqapi",
  timeout: 50000,
});
myAxios.interceptors.request.use(
  (config) => {
    let TokenObj = getToken();
    TokenObj = TokenObj ? JSON.parse(TokenObj) : "";
    let Token = JSON.parse(localStorage.getItem("auth_token"));
    if (TokenObj || Token) {
      config.headers.Authorization = TokenObj
        ? `${TokenObj.token_type} ${TokenObj.access_token}`
        : `bearer ${Token}`;
    } else {
      config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN;
    }
    return config;
  },
  // (config) => {
  //   let TokenObj = getToken()
  //   if (TokenObj) {
  //     TokenObj = JSON.parse(TokenObj)
  //     config.headers.Authorization = `${TokenObj.token_type} ${TokenObj.access_token}`
  //   } else {
  //     config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN
  //   }

  //   return config
  // },
  (error) => {
    store.commit("playLifeLoading", false);
    return Promise.reject(error);
  }
);

myAxios.interceptors.response.use(
  (config) => {
    return config.data;
  },
  (error) => {
    store.commit("playLifeLoading", false);
    return Promise.reject(error);
  }
);

export default myAxios;
