import crewordRequest from "./crewordRequest";
//返回语库列表
export function oftenLibraryList(data) {
  return crewordRequest({
    url: "/goldensentence/often-library/oftenLibraryList",
    method: "post",
    data,
  });
}

//查询常用语句
export function findOftenWord(params) {
  return crewordRequest({
    url: "/contextual/pageList",
    method: "get",
    params,
  });
}

// 金句搜索列表
export function oftenWordSearch(params) {
  return crewordRequest({
    url: "/goldensentence/often-word/oftenWordSearch",
    method: "get",
    params,
  });
}

//收藏
export function goldensentenceCollectList(params) {
  return crewordRequest({
    url: "/goldensentence/often-word/collectList",
    method: "get",
    params,
  });
}
//新建句库
export function addOrUpdateOftenLibrary(data) {
  return crewordRequest({
    url: "/goldensentence/often-library/addOrUpdateOftenLibrary",
    method: "post",
    data,
  });
}

//新建主题
export function addOrUpdateOftenType(data) {
  return crewordRequest({
    url: "/goldensentence/often-type/addOrUpdateOftenType",
    method: "post",
    data,
  });
}

//获取个人句库
export function findPersonalOftenLibrary() {
  return crewordRequest({
    url: "/goldensentence/often-library/findPersonalOftenLibrary",
    method: "get",
  });
}

//根据句库ID查询主题
export function loadOftenTypeByLibId(data) {
  return crewordRequest({
    url: "/goldensentence/often-type/loadOftenTypeByLibId",
    method: "post",
    data,
  });
}

//获取朝代信息
export function findAncientTimes(data) {
  return crewordRequest({
    url: "/ancientTimes/findAncientTimes",
    method: "get",
    data,
  });
}

//获取主题
export function findOftenLibraryType(params) {
  return crewordRequest({
    url: "/contextual/findByPid",
    method: "get",
    params,
  });
}

//新建金句
export function addOrUpdateOftenWord(data) {
  return crewordRequest({
    url: "/goldensentence/often-word/addOrUpdateOftenWord",
    method: "post",
    data,
  });
}

//样表下载
export function downloadTestExcel() {
  return crewordRequest({
    url: "/goldensentence/often-word/downloadTestExcel",
    method: "get",
    responseType: "blob",
  });
}

//样表下载
export function uploadOftenWord(data) {
  return crewordRequest({
    url: "/goldensentence/often-word/uploadOftenWord",
    method: "post",
    data,
  });
}

export function collectOftenWord(params) {
  return crewordRequest({
    url: "/goldensentence/often-word/collect",
    method: "get",
    params,
  });
}
//收藏列表
export function collectList(params) {
  return crewordRequest({
    url: "contextual/collectList",
    method: "get",
    params,
  });
}

//获取已选中的句库
export function added(params) {
  return crewordRequest({
    url: "/contextual/boughtAndChecked",
    method: "get",
    params,
  });
}

//获取可添加句库
export function notadded(params) {
  return crewordRequest({
    url: "/contextual/bought",
    method: "get",
    params,
  });
}

//添加选择句库
export function addOftenUseLibrary(data) {
  return crewordRequest({
    url: "/contextual/saveBought",
    method: "post",
    data,
  });
}

//删除句库
export function deleteOftenUseLibrary(data) {
  return crewordRequest({
    url: "/contextual/saveBought",
    method: "post",
    data,
  });
}

// 埋点接口
export function templateDownload(params) {
  return crewordRequest({
    url: "/contextual/templateDownload",
    method: "GET",
    params,
  });
}

//  查询选中库
export function findSelectedLib(params) {
  return crewordRequest({
    url: "/contextual/findSelectedLib",
    method: "get",
    params,
  });
}
