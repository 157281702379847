var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropDownList"},[_c('el-dropdown',{ref:"dropmenu",attrs:{"trigger":"click"},on:{"visible-change":_vm.visibleChange}},[_c('span',{staticClass:"el-dropdown-link"},[_c('img',{attrs:{"src":require('assets/images/idiom/particle.png'),"alt":""}}),_c('span',{class:{
          widthAuto:
            _vm.$route.name == 'GoldenSentenceIndex' || _vm.$route.name == 'Search',
        }},[_vm._v(_vm._s(_vm.choose.split('算法引擎')[0]))]),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',{staticClass:"menu-title"},[_vm._t("title",function(){return [_vm._v("选择算法引擎")]})],2),_c('el-menu',{attrs:{"default-active":"0","unique-opened":true},on:{"select":_vm.handleMenuSelect}},[_vm._l((_vm.list),function(group,gi){return [(group.libraryType == 1)?_c('el-menu-item',{key:'f' + group.id,attrs:{"index":gi + ''}},[_c('el-radio',{attrs:{"label":group.id},model:{value:(_vm.choosePriceId),callback:function ($$v) {_vm.choosePriceId=$$v},expression:"choosePriceId"}},[_vm._v(" "+_vm._s(group.conName.split('算法引擎')[0])+" ")])],1):_c('el-submenu',{key:group.id,attrs:{"index":gi + ''},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(group.conName)+" "),_c('el-tooltip',{attrs:{"effect":"light","content":group.remark,"placement":"right","popper-class":"custom-popper"}},[(group.isVip != 1)?_c('img',{attrs:{"src":group.isVip == 2
                      ? require('assets/images/headers/vip.png')
                      : group.isVip == 3
                      ? require('assets/images/headers/svip.png')
                      : require('assets/images/headers/plus.png'),"alt":""},on:{"click":function($event){return _vm.memberRenewal(group)}}}):_vm._e()])]},proxy:true}],null,true)},_vm._l((group.children),function(item,ii){return _c('el-tooltip',{key:item.id,attrs:{"effect":"light","placement":"right","popper-class":"custom-popper"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(item.tip)},slot:"content"}),_c('el-menu-item',{attrs:{"index":gi + '-' + ii}},[(item.checked != 2)?_c('el-radio',{attrs:{"label":item.id},model:{value:(_vm.choosePriceId),callback:function ($$v) {_vm.choosePriceId=$$v},expression:"choosePriceId"}},[_vm._v(_vm._s(item.conName.split('算法引擎')[0]))]):_c('div',{staticClass:"shop"},[_c('span',[_vm._v(_vm._s(item.conName.split('算法引擎')[0]))])])],1)],1)}),1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }