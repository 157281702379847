<template>
  <div class="dropDownList">
    <el-dropdown trigger="click" @visible-change="visibleChange" ref="dropmenu">
      <span class="el-dropdown-link">
        <img :src="require('assets/images/idiom/particle.png')" alt="" />
        <span
          :class="{
            widthAuto:
              $route.name == 'GoldenSentenceIndex' || $route.name == 'Search',
          }"
          >{{ choose.split('算法引擎')[0] }}</span
        >
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="menu-title">
          <slot name="title">选择算法引擎</slot>
        </div>
        <el-menu
          default-active="0"
          :unique-opened="true"
          @select="handleMenuSelect"
        >
          <template v-for="(group, gi) in list">
            <el-menu-item
              v-if="group.libraryType == 1"
              :key="'f' + group.id"
              :index="gi + ''"
            >
              <el-radio v-model="choosePriceId" :label="group.id">
                {{ group.conName.split('算法引擎')[0] }}
              </el-radio>
            </el-menu-item>
            <el-submenu v-else :key="group.id" :index="gi + ''">
              <template #title>
                {{ group.conName }}
                <el-tooltip
                  effect="light"
                  :content="group.remark"
                  placement="right"
                  popper-class="custom-popper"
                >
                  <img
                    v-if="group.isVip != 1"
                    :src="
                      group.isVip == 2
                        ? require('assets/images/headers/vip.png')
                        : group.isVip == 3
                        ? require('assets/images/headers/svip.png')
                        : require('assets/images/headers/plus.png')
                    "
                    @click="memberRenewal(group)"
                    alt=""
                  />
                </el-tooltip>
              </template>

              <el-tooltip
                v-for="(item, ii) in group.children"
                effect="light"
                :key="item.id"
                placement="right"
                popper-class="custom-popper"
              >
                <div slot="content" v-html="item.tip"></div>
                <el-menu-item :index="gi + '-' + ii">
                  <el-radio
                    v-model="choosePriceId"
                    :label="item.id"
                    v-if="item.checked != 2"
                    >{{ item.conName.split('算法引擎')[0] }}</el-radio
                  >
                  <div class="shop" v-else>
                    <!-- <img
                      :src="
                        require('assets/images/knowledgeService/gouwuche.png')
                      "
                      alt=""
                    /> -->
                    <span>{{ item.conName.split('算法引擎')[0] }}</span>
                  </div>
                </el-menu-item>
              </el-tooltip>
            </el-submenu>
          </template>
        </el-menu>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {
  findSelectedLib,
  setting,
  contextualList,
  contextualRecommend,
  getProductListGroup,
} from 'api/dropDownList'
import { getToken } from 'utils/auth'
// import defineSteps from 'views/idiom/driver/driver.js'
import { debounce } from 'utils/utils'
export default {
  name: 'dropDownList',
  components: {},
  props: {
    groupId: {},
    showll: {},
  },
  data() {
    return {
      list: [],
      choose: '',
      choosePriceId: 0,
      routeName: '',
    }
  },
  methods: {
    visibleChange(visible) {
      visible && this.getLibGroup(this.groupId)
    },
    handleMenuSelect: debounce(async function (path, arr) {
      path = path.split('-')
      let gi, ii, item
      gi = path[0]
      if (path.length == 1) {
        item = this.list[gi]
      } else {
        ii = path[1]
        item = this.list[gi].children[ii]
      }
      if (item.checked != 2) {
        this.choose = item.conName
        this.choosePriceId = item.id
        const params = {
          id: item.id,
          groupId: this.groupId == 4 ? 4 : item.groupId,
        }
        await setting(params)
        this.$emit('handleClick', item)
      } else {
        // this.$router.push("/VIPcenter");
        this.memberRenewal(item)
      }
      this.$refs['dropmenu'].hide()
      this.$bus.$emit('changeEngine')
    }),
    handleHref(productId) {
      try {
        let TokenObj = getToken()
        let access_token = ''
        if (TokenObj) {
          TokenObj = JSON.parse(TokenObj)
          access_token = `${TokenObj.access_token}`
        }
        // eslint-disable-next-line
        OpenBrowser(
          `https://www.writemall.com/details/${productId}?token=${access_token}`
        )
      } catch (e) {
        console.log(e)
      }

      window.isifHaveBuyDialog()
    },
    memberRenewal(item, event) {
      // this.$router.push("/VIPcenter");
      this.$store.commit('setLockClickText', item)
      this.$bus.$emit('buyTipsLock')
    },
    //获取库菜单
    async getLibGroup(groupId) {
      const form = new FormData()
      form.append('groupId', groupId)
      const res = await getProductListGroup(form)
      if (res.code == 200) {
        if (this.showll == 4) {
          res.data.pop()
        }
        this.list = res.data

        this.list.forEach((group) => {
          if (group.libraryType != 1) {
            group.children.length &&
              group.children.forEach((item) => {
                if (item.checked == 1) {
                  this.choose = item.conName
                  this.choosePriceId = item.id
                }
              })
          } else {
            if (group.checked == 1) {
              this.choose = group.conName
              this.choosePriceId = group.id
            }
          }
        })
      }
    },
    // 获取库
    async getlist(groupId) {
      const params = {
        groupId,
      }
      let res
      if (this.$route.path == '/idiom') {
        res = await contextualRecommend(params)
      } else {
        res = await contextualList(params)
      }

      this.list = res.data
      this.chooseText(groupId)
    },

    //已选中的
    async chooseText(groupId) {
      const params = {
        groupId,
      }

      const res = await findSelectedLib(params)

      this.list.forEach((group) => {
        group.children.length &&
          group.children.forEach((item) => {
            if (res.data && item.id == res.data.libraryId) {
              this.choose = item.conName
              this.choosePriceId = res.data.libraryId
            }
          })
      })
    },

    // item 点击
    async handleClick(val) {
      this.list.forEach((item) => {
        if (item.id == val.id) {
          this.choose = item.conName
          this.choosePriceId = val.id
        }
      })

      // console.log(val);
      const params = {
        id: val.id,
        groupId: this.groupId == 4 ? 4 : val.groupId,
      }

      const res = await setting(params)
      this.$emit('handleClick', val)
    },

    handleAddClick() {
      if (this.groupId == 2) {
        window.openfreeSentencePopup()
      }

      if (this.groupId == 3) {
        window.openAddPopup()
      }
    },
  },
  watch: {},
  created() {},
}
</script>

<style lang="scss" scoped>
.el-dropdown-menu {
  .menu-title {
    margin-bottom: 5px;
    text-align: center;
    color: #303133;
    font-size: 14px;
  }
  ::v-deep .el-menu {
    > .el-menu-item {
      height: 28px;
      line-height: 28px;
      padding-left: 10px !important;
      padding-right: 10px;
      font-size: 14px;
    }
    .el-submenu {
      @include flex-center(column);
      justify-content: stretch;
      align-items: stretch;
      .el-menu {
        max-height: 168px;
        overflow-y: scroll;
        .el-menu-item {
          height: 28px;
          line-height: 28px;
          padding-left: 20px !important;
          padding-right: 20px;
          .el-radio__input.is-checked + .el-radio__label {
            color: #303133;
          }
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar {
          width: 3px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ff6900;
        }
      }
      .el-submenu__title {
        min-width: 225px;
        height: 28px;
        line-height: 28px;
        padding-left: 10px !important;
        padding-right: 10px;
        font-size: 14px;
      }
      .shop {
        cursor: pointer;
        display: flex;
        color: #303133;
        font-size: 14px;
        align-items: center;
        img {
          margin-right: 10px;
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: #ffbf6914;
        }
      }
    }
  }
}
.el-dropdown-link {
  @include flex-start;
  cursor: pointer;
  color: #ff6900;
  > span {
    margin-left: 5px;
    color: #000;
  }
  .widthAuto {
    min-width: 52px;
  }
}
.el-icon-arrow-down {
  font-size: 12px;
}

.dropDownList {
  // padding: 10px 0 0 30px;
}
</style>
