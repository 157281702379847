export const ACCEPT_CONFIG = {
  image: [".png", ".jpg", ".jpeg", ".gif", ".bmp"],
  video: [
    ".mp4",
    ".m4v",
    ".rmvb",
    ".mkv",
    ".wmv",
    ".flv",
    ".asx",
    ".asf",
    ".mpg",
    ".mpeg",
    ".mpe",
    ".3gp",
    ".mov",
    ".avi",
    ".dat",
    ".vob",
  ],
  word: [".doc", ".docx"],
  audio: [".mp3", ".wma", ".rm", ".wav", ".midi"],
  document: [
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".pdf",
    ".txt",
    ".tif",
    ".tiff",
    ".rar",
    ".zip",
  ],
  getAll(fileType) {
    if (fileType == 1) {
      return [...this.word]; //word文件
    } else if (fileType == 2) {
      return [...this.image]; //图片文件
    } else if (fileType == 3) {
      return [...this.video]; //视频文件
    } else if (fileType == 4) {
      //音频文件
      return [...this.audio];
    } else if (fileType == 5) {
      return [...this.document]; //文档
    } else {
      return [
        ...this.word,
        ...this.image,
        ...this.video,
        ...this.audio,
        ...this.document,
      ];
    }
  },
  getImage() {
    return [...this.image];
  },
  getVideo() {
    return [...this.video];
  },
  getDocument() {
    return [...this.document];
  },
};
